<template>
    <div>
        <!-- <pre>{{formStateStore.reportred.mockData}}</pre> -->
        <a-form
            id="testp"
            :model="formState"
            name="basic"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 24 }"
            autocomplete="off"
            @finish="onFinish"
            @finishFailed="onFinishFailed"
        >
            <div id="my-element" class="main-content">
                <div
                    id="title_report_red"
                    style="font-size:30px;text-align: center;background-color: red;color: white;padding: 20px;display: none;"
                >
                    ใบรายการจัดส่ง
                </div>

                <a-divider orientation="left">ข้อมูลลูกค้า</a-divider>
                <div class="p-5 mb-5 card">
                    <a-row type="flex">
                        <a-col :span="3" :order="1">
                            <font-awesome-icon icon="fa-solid fa-user" />
                            <span class="pl-2"> ข้อมูลลูกค้า</span>
                        </a-col>
                        <a-col :span="9" :order="2">
                            <a-form-item
                                :name="['user', 'customercode']"
                                label="รหัสลูกค้า"
                                :rules="[
                                    { required: true, message: 'กรุณากรอก' }
                                ]"
                            >
                                <!-- <div>{{ formState.user.customercode }}</div> -->
                                <!-- <vue-barcode
                                    :value="'https://www.khunlookkor.com/selllist?customercode='+formState.user.customercode+'issshowdetail=true'"
                                    :options="{ displayValue: false }" style="width:200px">
                                </vue-barcode> -->
                                <!-- <button :click="gotodetail()">ดูรายละเอียด</button> -->
                                <!-- <a-input v-model:value="formState.user.customercode" placeholder="opendetail">
                                </a-input> -->
                                <!-- <a-input :disabled="true" style="background: #80808038"
                                    v-model:value="formState.user.customercode" /> -->
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col :span="3" :order="1">
                            ช่องทางการติดต่อ
                        </a-col>
                        <a-col :span="6" :order="2">
                            <a-input
                                v-model:value="formState.user.customerlineid"
                                placeholder="LINE ID"
                                style="border: none;font-size:20px"
                            >
                                <template #prefix>
                                    <img
                                        class="w-5"
                                        src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                                    />
                                </template>
                            </a-input>
                            <a-input
                                v-model:value="formState.user.customerfacebook"
                                placeholder="FACEBOOK"
                                style="border: none;font-size:20px"
                            >
                                <template #prefix>
                                    <img
                                        class="w-5"
                                        src="https://i.pinimg.com/originals/af/86/da/af86da825329b7138bb7ccc42ee01e3a.jpg"
                                    />
                                </template>
                            </a-input>
                        </a-col>
                        <a-col :span="3" :order="3">ชื่อการติดต่อ</a-col>
                        <a-col :span="6" :order="4">
                            <div class="p-2">
                                {{ formState.user.customername }}
                            </div>
                            <div class="p-2">
                                {{ formState.user.customerlastname }}
                            </div>
                            <div class="p-2">
                                <span v-show="formState.user.customernickname"
                                    >({{ formState.user.customernickname }})
                                </span>
                            </div>
                        </a-col>
                    </a-row>
                </div>

                <a-divider orientation="left">ที่อยู่-ลูกค้า(1.1)</a-divider>
                <!-- <pre> :: {{formState.user}}</pre> -->
                <div class="p-5 mb-5 card">
                    <a-row type="flex">
                        <a-col :span="3" :order="1">
                            <font-awesome-icon
                                icon="fa-solid fa-address-book"
                            />
                            <span class="pl-2"> ที่อยู่จัดส่ง(1.1)</span>
                        </a-col>
                        <a-col :span="6" :order="2">
                            <a-form-item :name="['user', 'customercountry']">
                                <div v-show="formState.user.addressdesc">
                                    ที่อยู่ : {{ formState.user.addressdesc }}
                                </div>
                                <div
                                    v-show="
                                        formState.user.customeraddressType ==
                                            '1'
                                    "
                                >
                                    ประเทศ ไทย
                                </div>
                                <div
                                    v-show="
                                        formState.user.customeraddressType ==
                                            '2'
                                    "
                                >
                                    ต่างประเทศ
                                </div>

                                <Address
                                    v-if="
                                        isshowsentaddress &&
                                            formState.user
                                                .customeraddressType == '1'
                                    "
                                    :key="update"
                                    :setprovince="formState.user.ProvinceName"
                                    :setdistricts="formState.user.BorderName"
                                    :setsubdistricts="
                                        formState.user.DistrictName
                                    "
                                    :setpostcode="formState.user.PostCode"
                                    @province="$event;"
                                    @districts="ondistricts($event)"
                                    @subdistricts="onsubdistricts($event)"
                                    @postcode="onpostcode($event)"
                                    :isshowinput="false"
                                ></Address>
                                <SelectOption
                                    :colorinput="'transparent'"
                                    :formStates="formState"
                                    v-if="
                                        formState.user.customeraddressType ==
                                            '2'
                                    "
                                    v-model:value="formState.user.selectcountry"
                                    :isshowinput="false"
                                ></SelectOption>
                            </a-form-item>
                        </a-col>
                        <a-col :span="6" :order="3">
                            <div
                                v-if="
                                    !formState.user.selectcountry
                                        .selectcountrycodenumber
                                "
                            >
                                เบอร์ติดต่อ :
                                {{ formState.user.customerphonethai }}
                            </div>
                            <div
                                v-else-if="
                                    formState.user.selectcountry
                                        .selectcountrycodenumber
                                "
                            >
                                เบอร์ติดต่อต่างประเทศ
                                <div
                                    v-if="
                                        formState.user.selectcountry
                                            .selectcountrycode
                                    "
                                >
                                    {{
                                        formState.user.selectcountry
                                            .selectcountrycodenumber
                                    }}
                                    {{ formState.user.customerphonethai }}
                                </div>
                            </div>
                            <div>
                                เบอร์ติดต่อ(ฉุกเฉิน) :
                                {{ formState.user.customerphoneemergency }}
                            </div>
                        </a-col>
                        <a-col :span="6" :order="3">
                            <a-button type="primary" @click="showModaladdress"
                                >เปลี่ยนแปลงที่อยู่</a-button
                            >
                        </a-col>
                    </a-row>
                </div>
                <a-row>
                    <a-col flex="1" :order="1">
                        <div>
                            <a-modal
                                v-model:visible="visibleaddress"
                                width="1200px"
                                title="เปลี่ยนแปลงที่อยู่"
                                @ok="handleOkaddress"
                            >
                                <AddressFrom
                                    :textbtn="'เปลี่ยนแปลงที่อยุ่'"
                                    :isshowadd="false"
                                    :formStates="formState"
                                    v-model:value="formState.user.addressfrom"
                                    @onaddresschange="onaddresschange()"
                                >
                                </AddressFrom>
                            </a-modal>
                        </div>
                    </a-col>
                </a-row>
                <ProductListByCustomerCode
                    v-if="
                        Storedata.methods.checkGrantProductListByCustomerCode()
                    "
                    :rowselected="rowselected"
                    @selectedsellorder="showdetialselectedsellorder($event)"
                >
                </ProductListByCustomerCode>

                <MoreDelivery
                    v-if="Storedata.methods.checkGrantMoreDelivery()"
                    :rowselected="rowselected"
                    @selectedsellorder="showdetialselectedRowKeys($event)"
                >
                </MoreDelivery>
                <!-- <vue3-simple-html2pdf ref="vue3SimpleHtml2pdf" :options="pdfOptions" :filename="exportFilename"> -->

                <div class="p-5 mb-5 card">
                    <TableAcceptCreateDelivery
                        v-if="!isshowpackproduct"
                    ></TableAcceptCreateDelivery>
                    <CreateDeliveryTranfer
                        v-if="isshowpackproduct"
                        :isdisable="true"
                    ></CreateDeliveryTranfer>

                    <CreateDeliveryTrackingTranfer
                        :key="formStateStore.updatecomponent"
                        v-if="isshowpackproduct"
                        :isdisable="true"
                    >
                    </CreateDeliveryTrackingTranfer>
                </div>
            </div>
            <CreateDeliveryTracking
                v-slot="slotProps"
                v-if="
                    isshowpackproduct &&
                        formStateStore.btnsubmit == 'สร้าง Tracking No.'
                "
            >
                <div class="pt-2" v-show="slotProps.selected == 'thailandpost'">
                    <DetailSent
                        :key="updatecomponent"
                        v-if="
                            isshowpackproduct &&
                                formStateStore.btnsubmit == 'สร้าง Tracking No.'
                        "
                        @updatedata="updatetracktable($event)"
                    >
                    </DetailSent>
                </div>
            </CreateDeliveryTracking>
            <!-- </vue3-simple-html2pdf> -->
            <!-- <a @click="isshowcanceldelivery = !isshowcanceldelivery"><u>ยกเลิกการจัดส่ง</u></a>
        <UpdateStatusPopup v-if="isshowcanceldelivery" :formState="formStateStore" :title="'ยกเลิกการจัดส่ง'"
            :subtitle="'*เมื่อยกเลิกสินค้าแล้วจะไม่สามารถเรียกกลับได้'" @close="setshowpopup($event)">
        </UpdateStatusPopup> -->
            <div v-show="formStateStore.rowselected_status != 'cancel'">
                <div>
                    <a-form-item
                        v-show="
                            isshowpackproduct &&
                                formStateStore?.listcreatedelivery.length > 0
                        "
                    >
                        <u
                            class="text-cancel"
                            @click="
                                isshowcanceldelivery = !isshowcanceldelivery
                            "
                            >ยกเลิกการจัดส่ง</u
                        >
                        <UpdateStatusPopupCancelDelivery
                            v-if="isshowcanceldelivery"
                            :formState="formState"
                            :title="'ยกเลิกการจัดส่ง'"
                            :subtitle="
                                '*เมื่อยกเลิกสินค้าแล้วจะไม่สามารถเรียกกลับได้'
                            "
                            @close="setshowpopup($event)"
                        >
                        </UpdateStatusPopupCancelDelivery>
                    </a-form-item>

                    <a-button
                        v-show="
                            Storedata.methods.checkgrantbtnsaveDelivery() &&
                                formStateStore.btnsubmit != 'สร้าง Tracking No.'
                        "
                        style="float: right"
                        type="primary"
                        @click="isClickSubmit()"
                    >
                        {{ formStateStore.btnsubmit }}</a-button
                    >
                </div>
            </div>
            <!-- <a-button v-show="formStateStore.rowselected_status != 'cancel' && isshowpackproduct" @click="download">
                Download pdf</a-button> -->

            <div>
                <div>
                    <div v-if="is_ProfessorType_7_8">
                        <a-button
                            v-show="
                                formStateStore.rowselected_status != 'cancel' &&
                                    isshowpackproduct &&
                                    formState.user.addressalllist.length == 0 &&
                                    ishastrack &&
                                    is_ProfessorType_7_8
                            "
                            type="primary"
                            shape="round"
                            :size="size"
                        >
                            <template #icon>
                                <DownloadOutlined />
                            </template>
                            <a-popconfirm
                                title="ต้องการโหลด?"
                                @confirm="download"
                                @cancel="cancel"
                            >
                                Download ใบแดง
                            </a-popconfirm>
                        </a-button>
                    </div>
                    <div v-else>
                        <a-button
                            v-show="
                                formStateStore.rowselected_status != 'cancel' &&
                                    isshowpackproduct
                            "
                            type="primary"
                            shape="round"
                            :size="size"
                        >
                            <template #icon>
                                <DownloadOutlined />
                            </template>
                            <a-popconfirm
                                title="ต้องการโหลด?"
                                @confirm="download"
                                @cancel="cancel"
                            >
                                Download ใบแดง
                            </a-popconfirm>
                        </a-button>
                    </div>

                    <div class="mt-3">
                        <a-typography-text
                            v-show="
                                formStateStore.rowselected_status != 'cancel' &&
                                    isshowpackproduct &&
                                    formState.user.addressalllist.length == 0 &&
                                    !is_ProfessorType_7_8
                            "
                            class="text-cancel pl-3"
                            type="warning"
                            >*กรุณาตรวจสอบเข้าไปดูที่อยู่จัดส่งทุกครั้ง</a-typography-text
                        >

                        <a-typography-text
                            v-show="
                                formStateStore.rowselected_status != 'cancel' &&
                                    isshowpackproduct &&
                                    formState.user.addressalllist.length == 0 &&
                                    ishastrack &&
                                    is_ProfessorType_7_8_9
                            "
                            class="text-cancel"
                            type="text"
                            >*download ได้เนื่องจาก (ประเภทพัสดุ
                            <u>สั่งไลน์,ส่งเลย</u> ไม่ต้องสร้าง
                            Tracking)</a-typography-text
                        >
                    </div>

                    <!-- <pre>{{ formState }}</pre> -->
                </div>
                <!-- <a v-if="pdfData" :href="pdfData" target="_blank">
                    Preview and Print
                </a> -->
            </div>
        </a-form>
        <!-- เรียกกัน error -->
        <ProductItems v-if="flase"></ProductItems>
        <Address v-if="flase"></Address>
        <!-- เรียกกัน error -->
        <div class="pt-4">
            <!-- แสดง จากการกด SO รายการขาย -->
            <FromCustomerProductDetail
                v-if="false && isshowDetailSellOrder.isshow"
                :rowselected="rowselected"
                :isshowDetailSellOrder="isshowDetailSellOrder"
            ></FromCustomerProductDetail>
            <!-- แสดง จากการกด SO รายการขาย -->
        </div>
        <a-modal
            v-model:visible="visible_export_rang"
            title="เลือกช่วงรายการ"
            @ok="handleOk_export_rang"
        >
            <p>เนื่องจากมีรายการจำนวนมาก โปรดทยอยเลือกเลือกช่วงที่ Export</p>
            <p>
                <a-typography-text underline
                    >มีรายการทั้งหมด
                    {{ formState.listcreatedelivery.length }}
                    รายการ</a-typography-text
                >
            </p>

            <a-input-group compact>
                <a-input
                    v-model:value="export_criteria.start"
                    type="number"
                    style="width: 100px; text-align: center"
                    placeholder="Minimum"
                />
                <a-input
                    style="width: 30px; border-left: 0; pointer-events: none; background-color: #fff"
                    placeholder="~"
                    disabled
                />
                <a-input
                    type="number"
                    v-model:value="export_criteria.end"
                    style="width: 100px; text-align: center; border-left: 0"
                    placeholder="Maximum"
                />
            </a-input-group>
        </a-modal>
    </div>
</template>
<script>
import AddressFrom from "@/module/CustomerOrder/SellCreate/component/form/AddressFrom.vue";
import Utility from "@/helper/Utility.js";
import {
    defineComponent,
    reactive,
    onMounted,
    ref,
    computed,
    watch
} from "vue";
import ProductItems from "../../CustomerOrder/SellCreate/component/ProductItems.vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import moment from "moment";
import axios from "axios";
import Storedata from "@/store/Storedata.js";
import { Modal } from "ant-design-vue";
// import Uploadfile from "../component/form/Uploadfile.vue";
import Address from "../../CustomerOrder/SellCreate/component/Address.vue";
import FromCustomerProductDetail from "@/module/CustomerOrder/SellCreate/component/FromCustomerProductDetail.vue";
// import Inputnumber from "@/module/Sell/SellCreate/component/form/Inputnumber.vue";
import { FilePdfOutlined, UserOutlined } from "@ant-design/icons-vue";
import SelectOption from "@/module/Sell/SellCreate/component/form/SelectOption.vue";

import _ from "lodash";
import { FormHelper } from "@/helper/FormHelper.js";
import {
    ContactModel,
    GetContactModel,
    CustomerModel,
    ProductModel,
    ProductDeliveryAddressModel,
    PaymentModel
} from "@/core/Create/model/CreateModel";
import { notification } from "ant-design-vue";
import ProductListByCustomerCode from "@/module/CustomerOrder/SellCreate/component/form/ProductListByCustomerCode.vue";
import { formStateStore } from "@/store/storebackoffice";
import LocaleMixin from "@/mixin/LocaleMixin.js";
import MoreDelivery from "@/module/Delivery/component/MoreDelivery.vue";
import { useRoute } from "vue-router";
import TableAcceptCreateDelivery from "./TableAcceptCreateDelivery.vue";
import CreateDeliveryTranfer from "./CreateDeliveryTranfer.vue";
import CreateDeliveryTrackingTranfer from "./CreateDeliveryTrackingTranfer.vue";
import CreateDeliveryTracking from "./CreateDeliveryTracking.vue";
import DetailSent from "@/module/DashboardInfo/component/DetailSent.vue";

import UpdateStatusPopupCancelDelivery from "@/module/Delivery/component/UpdateStatusPopupCancelDelivery.vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import VueBarcode from "@chenfengyuan/vue-barcode";
import uniqBy from "lodash/uniqBy";
import Account from "@/helper/AccountHelper.js";
import dayjs from "dayjs";
export default defineComponent({
    name: "FromCreate",
    provide: {
        Storedata: Storedata
    },
    props: {
        rowselected: null
    },
    mixins: [LocaleMixin],
    components: {
        AddressFrom,
        VueBarcode,
        FilePdfOutlined,
        UpdateStatusPopupCancelDelivery,
        CreateDeliveryTrackingTranfer,
        CreateDeliveryTracking,
        FromCustomerProductDetail,
        ProductItems,
        // Uploadfile,
        Address,
        // Inputnumber,
        UserOutlined,
        SelectOption,
        // NumberOutlined,
        ProductListByCustomerCode,
        // Baseinput,
        MoreDelivery,
        TableAcceptCreateDelivery,
        CreateDeliveryTranfer,
        DetailSent
    },
    methods: {
        // download() {

        //     try {
        //         this.$refs.vue3SimpleHtml2pdf.download();
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
        show_hide_column(col_no, do_show) {
            const thead = document.querySelectorAll(".ant-table-thead");
            for (const box of thead) {
                box.classList.add("ant-table-thead-zoom");
            }

            const cell = document.querySelectorAll(".ant-table-cell");

            for (const box of cell) {
                box.classList.add("ant-table-cell-zoom");
            }
            const text = document.querySelectorAll(".ant-divider-inner-text");

            for (const box of text) {
                box.classList.add("ant-table-cell-zoom");
            }
            const form_item = document.querySelectorAll(".ant-form-item");

            for (const box of form_item) {
                box.classList.add("ant-table-cell-zoom");
            }

            const required = document.querySelectorAll(
                ".ant-form-item-required"
            );

            for (const box of required) {
                box.classList.add("ant-table-cell-zoom");
            }

            const content = document.querySelectorAll(
                ".ant-form-item-control-input-content"
            );

            for (const box of content) {
                box.classList.add("ant-table-cell-zoom");
            }

            // const scanproduct = document.getElementById('scanproduct')
            // const addproduct_id = document.getElementById('addproduct_id')
            // const edit_contact_id = document.getElementById('edit_contact_id')
            // const contact_component_id = document.getElementById('contact_component_id')
            // const title_customer_id = document.getElementById('title_customer_id')
            // const title_address_id = document.getElementById('title_address_id')

            // title_address_id.style.visibility = do_show ? "" : "collapse";
            // title_customer_id.style.visibility = do_show ? "" : "collapse";
            // contact_component_id.style.visibility = do_show ? "" : "collapse";
            // edit_contact_id.style.visibility = do_show ? "" : "collapse";
            // addproduct_id.style.visibility = do_show ? "" : "collapse";
            // scanproduct.style.visibility = do_show ? "" : "collapse";

            //icon
            const title_report_red = document.getElementById(
                "title_report_red"
            );
            title_report_red.style.display = do_show ? "none" : "";
            //icon
        },
        download() {
            this.reportsettingred();

            return;
            Storedata.state.loading.percent += 50;
            Storedata.state.loading.ishow = true;
            this.show_hide_column(1, false);
            // Select the element to be captured
            const element = document.querySelector("#my-element");

            // Capture the screenshot
            html2canvas(element).then(canvas => {
                const classcontent = document.querySelectorAll(
                    ".ant-table-cell-zoom"
                );
                for (const box of classcontent) {
                    box.className = "";
                }
                classcontent.className = "";

                this.show_hide_column(1, true);

                const imgWidth = 203;
                const pageHeight = 295;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                var heightLeft = imgHeight;
                const contentDataURL = canvas.toDataURL("image/png");
                const pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF

                var base64String =
                    "https://www.namahayan.com/_nuxt/NAMAHAYAN.e3c1cff0.webp";
                pdf.addImage(base64String, "PNG", 70, 5, 60, 12);
                var position = 18;
                pdf.addImage(
                    contentDataURL,
                    "PNG",
                    5,
                    position,
                    imgWidth,
                    imgHeight
                );
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position += heightLeft - imgHeight; // top padding for other pages
                    pdf.addPage();
                    pdf.addImage(
                        contentDataURL,
                        "PNG",
                        5,
                        position,
                        imgWidth,
                        imgHeight
                    );
                    heightLeft -= pageHeight;
                }
                pdf.save("ใบรายการจัดส่ง.pdf"); // Generated PDF
                Storedata.state.loading.percent += 100;
                setTimeout(() => {
                    Storedata.state.loading.ishow = false;
                }, 1500);
            });
        }
    },
    setup(props) {
        const path = window.location.host.includes("localhost")
            ? "http://localhost:8090"
            : process.env.VUE_APP_API_BASE_URL;
        const webpath = window.location.host.includes("localhost")
            ? "http://localhost:8080"
            : "https://www.khunlookkor.com/";
        const isshowsentaddress = ref(false);
        const isshowcanceldelivery = ref(false);
        const updatecomponent = ref(0);
        const route = useRoute();

        const visible_export_rang = ref(false);
        const current_export_type = ref("");
        const export_criteria = ref({ start: 1, end: 1 });

        console.log(
            "Storedata.state.profile : ",
            Storedata.state.profile.login.role
        );
        const rowselecteddetail = reactive(props.rowselected);
        console.log(rowselecteddetail);

        const title = "ลูกค้า";
        const producttype = ref([]);
        // const gender = ref("1");
        const typelocal = ref("1");
        const update = ref(0);

        const age = ref(18);
        moment.locale("th");
        const datecreatetoday = moment()
            .add(543, "year")
            .format("LL");
        const layout = {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 12
            }
        };
        const validateMessages = {
            required: "${label} is required!",
            types: {
                email: "${label} is not a valid email!",
                number: "${label} is not a valid number!"
            },
            number: {
                range: "${label} must be between ${min} and ${max}"
            }
        };

        const calculateAge = birthday => {
            console.log(birthday);
            if (birthday == null) return (formState.user.customerage = 0);
            // birthday is a date
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            formState.user.customerage = Math.abs(
                ageDate.getUTCFullYear() - 1970
            );
        };

        const calculateAge_setting = birthday => {
            // birthday is a date
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };
        const onModalAddressChagne = values => {
            console.log(values);
        };
        const onselectchange = value => {
            formState.user.customername =
                value[0].item.CustomerName +
                " " +
                value[0].item.CustomerLastName;
            formState.user.customertel = value[0].item.CustomerContractNumber;
            formState.user.customeremail = value[0].item.CustomerEmail;
            formState.user.lineid = value[0].item.lineid;
            formState.user.customerAddress = value[0].item.CustomerAddress;
            formState.user.customerbirthday = value[0].item.CustomerBirthDay;
            formState.user.customerintroduction = value[0].item.CustomerDesc;
            formState.user.customerage = calculateAge_setting(
                value[0].item.CustomerBirthDay
            );
        };
        const publishedBooksMessage = computed(() => {
            return Storedata.state.createproduct;
        });
        const getallproduct = computed(() => {
            return Storedata.state.allproduct;
        });
        const formState = reactive(formStateStore);
        formState.user.customercode = rowselecteddetail.rowselected;

        const updatetracktable = e => {
            updatecomponent.value++;
            formStateStore.updatecomponent++;
            if (e.deliverystatus) {
                apiupdateproductstatus(e.deliverystatus);
            }
        };

        const apiupdateproductstatus = async e => {
            var param = {
                DeliveryStatus: e,
                Desc: "เตรียมแพ็ค",
                AdditionalTage: formState.rowselecteddetail
            };

            await BzbsUser.apiupdateproductdeliverystatus(param)
                .then(res => {
                    console.log("update status ", res);
                    // visible.value = false;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        watch(
            () => formState.user.customeraddressType,
            () => {
                if (formState.user.customeraddressType == "1") {
                    formState.user.selectcountry.selectcountry = "TH";
                }
            },
            () => formState.user.selectcountry.selectcountry,
            () => {
                if (
                    listcodephone.value.find(
                        word =>
                            word.value ==
                            formState.user.selectcountry.selectcountry
                    )
                ) {
                    formState.user.phonecode = listcodephone.value.filter(
                        word =>
                            word.value ==
                            formState.user.selectcountry.selectcountry
                    );
                }
            }
        );
        // setTimeout(() => {
        //     formState.user.orderdate = moment().format("YYYY-MM-DD");
        // }, 100);
        onMounted(async () => {
            setTimeout(async () => {
                await getproductdeliveryaddress();
                await gencodecustomer();
                await getdeliveryaddress();
            }, 1000);
        });

        const getproductdeliveryaddress = () => {
            formState.user.addressfrom = [];
            const alllist = ref([]);

            // var type = window.location.pathname == '/sellcreate' ? 'all' : 'one'
            BzbsUser.apigetproductdeliveryaddress(
                formState.user.customercode,
                formState.user.sellorderTransaction,
                "all"
            )
                .then(res => {
                    console.log("res :", res);

                    res.data.forEach((element, index) => {
                        var param = {
                            key: index,
                            addresskey: element.AddressKey,
                            productaddressdeliveryorder:
                                element.ProductAddressDeliveryOrder,
                            productcheckedsend: element.CheckedSend,
                            checkedsend: element.CheckedSend,
                            name: element.Name,
                            lastname: element.Lastname,
                            address: element.Address,
                            phone: element.Phone,
                            phonecode: element.PhoneCode,
                            email: element.Email,
                            typeaddress: element.TypeAddress,
                            selectcountry: element.SelectCountry,
                            CountryName: element.CountryName,
                            ProvinceName: element.ProvinceName,
                            ProvinceID: element.ProvinceID,
                            BorderName: element.BorderName,
                            BorderID: element.BorderID,
                            DistrictName: element.DistrictName,
                            PostCode: element.PostCode,
                            SetDefault: element.SetDefault
                        };

                        // if (param.phonecode) formState.user.selectcountry.selectcountrycode = param.phonecode
                        if (element.SetDefault == "1") {
                            formState.useraddress = param;
                        }

                        if (window.location.pathname == "/sellcreate") {
                            var set_all_for_first_cretae = param;
                            set_all_for_first_cretae.SetDefault = undefined;
                            formState.user.addressfrom.push(
                                set_all_for_first_cretae
                            );
                        }
                        alllist.value.push(param);
                    });

                    // formState.user.addressalllist = alllist.value

                    console.log("alllist ::", alllist.value);
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const getdeliveryaddress = async () => {
            var param = {
                customerorder: formState.user.customercode,
                productsellorder: formStateStore.productsellorder,
                additionaltage: formState.rowselecteddetail
            };

            console.log(
                " formStateStore.productsellorder :",
                formStateStore.productsellorder
            );

            await BzbsUser.apigetdefaultproductdeliveryaddress(
                param.customerorder,
                param.productsellorder,
                param.additionaltage
            )
                .then(res => {
                    if (res.data.length == 0) return;

                    console.log("Sent Address ", res);
                    formStateStore.user.customernickname = res.data[0].Name;
                    formStateStore.user.customerlastname = res.data[0].Lastname;
                    formStateStore.user.customername = res.data[0].Name;
                    formState.user.customeraddressType =
                        res.data[0].TypeAddress;
                    formState.user.addressdesc = res.data[0].Address;
                    formState.user.ProvinceName = res.data[0].ProvinceName;
                    formState.user.BorderName = res.data[0].BorderName;
                    formState.user.DistrictName = res.data[0].DistrictName;
                    formState.user.PostCode = res.data[0].PostCode;

                    formStateStore.user.customeraddressType =
                        res.data[0].TypeAddress;
                    formStateStore.user.addressdesc = res.data[0].Address;
                    formStateStore.user.ProvinceName = res.data[0].ProvinceName;
                    formStateStore.user.BorderName = res.data[0].BorderName;
                    formStateStore.user.DistrictName = res.data[0].DistrictName;
                    formStateStore.user.PostCode = res.data[0].PostCode;
                    formStateStore.user.customerphonethai = res.data[0].Phone;

                    formStateStore.userproduct.addresskey =
                        res.data[0].AddressKey;
                    formStateStore.user.sellorderTransaction =
                        res.data[0].ProductSellOrder;
                    formStateStore.user.customerorder =
                        res.data[0].CustomerOrder;
                    formStateStore.userproduct.productkey =
                        res.data[0].ProductKey;

                    if (res.data[0].TypeAddress == "3") {
                        formStateStore.user.addressdesc = "รับสินค้าเอง";
                    }
                    // FullName: formStateStore.user.customername + ' ' + formStateStore.user.customerlastname,
                    //     Name: formStateStore.user.customernickname,
                    //         Phone: formStateStore.user.customerphonethai,
                    //             Address: formStateStore.user.addressdesc,
                    //                 District: formStateStore.user.District,
                    //                     DistrictName: formStateStore.user.DistrictName,
                    //                         Area: formStateStore.user.Area,
                    //                             BorderName: formStateStore.user.BorderName,
                    //                                 Province: formStateStore.user.Province,
                    //                                     Postcode: formStateStore.user.Postcode,
                    //                                         ProvinceName: formStateStore.user.ProvinceName,
                    //                                             Remark: formStateStore.user.Remark,
                    //                                                 prompostcode: formStateStore.user.prompostcode,
                    //                                                     provincepostinter: formStateStore.user.provincepostinter,
                    //                                                         citypostinter: formStateStore.user.citypostinter,
                    //                                                             postcodepostinter: formStateStore.user.postcodepostinter,
                    //                                                                 weight: "",
                    console.log("formState.user ", formState.user);
                    isshowsentaddress.value = true;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const isshowcustomerdescproduct = ref(true);
        const SellOrderTransaction = () => {
            BzbsUser.apiSellOrderTransaction(null)
                .then(res => {
                    setTimeout(() => {
                        isshowcustomerdescproduct.value = true;
                    }, 1000);
                    // formState.user.customercode =
                    //   "C-" + moment(new Date()).format("YYYYMMDDHHmmss");
                    return (formState.user.sellorderTransaction =
                        res.data[0].SellOrderTransactionID);
                })

                .catch(error => {
                    console.log(error);
                });
        };

        const imageslip = imglist => {
            if (imglist) {
                formState.user.fileList = imglist;
            }
        };
        const success = () => {
            Modal.success({
                title: "บันทึกสำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const options = ref([]);
        const handleChange = value => {
            onselectchange(options.value.filter(item => item.value == value));
        };

        const handleBlur = () => {
            console.log("blur");
        };

        const handleFocus = () => {
            console.log("focus");
        };

        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const queryProductType = () => {
            return axios.get(path + "/api/producttype").then(res => {
                res?.data[0]?.map(item => {
                    let obj = {};
                    obj.label = item.ProductName;
                    obj.value = item.ProductName;
                    producttype.value.push(obj);
                });
            });
        };

        const getCustomer = async customercode => {
            await BzbsUser.apiGetCustomercustomercode(customercode)
                .then(res => {
                    if (res.data[0]) {
                        return initfil(res.data[0]);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const onprovince = values => {
            console.log("onprovince", values);
            formState.user.ProvinceName = values.provinceName;
            formState.user.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.user.BorderName = values.districtsName;
            formState.user.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.user.DistrictID = values.subdistrictsID;
            formState.user.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.user.PostCode = values;
        };

        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });

                setTimeout(() => {
                    if (formState.user.selectcountry.selectcountry) {
                        listcodephone.value.forEach(element => {
                            if (
                                element.value ==
                                formState.user.selectcountry.selectcountrycode
                            ) {
                                formState.user.selectcountry.selectcountrycodenumber =
                                    element.label;
                            }
                        });
                    }
                }, 3000);

                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const initcontact = CustomerCode => {
            BzbsUser.apicontact(CustomerCode)
                .then(res => {
                    const temp_obj = [];
                    res.data?.forEach(element => {
                        element.sellorderTransaction =
                            formState.user.sellorderTransaction;
                        element.customercode = formState.user.customercode;
                        var obj = new GetContactModel(element);
                        console.log("obj", obj);
                        temp_obj.push(obj);
                    });

                    formState.getcontactlist = temp_obj;
                    formState.user.contactList = temp_obj;
                    console.log(
                        "formState.user.contactlist :",
                        formState.getcontactlist
                    );
                    formState.updatecomponent++;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const gencodecustomer = async () => {
            var e = {};
            if (rowselecteddetail.rowselected != undefined) {
                initcontact(rowselecteddetail.rowselected);
                await getCustomer(rowselecteddetail.rowselected);
            }
            if (e == "") {
                return;
            }
        };
        const initfil = async e => {
            formState.user.contact = e.Contact;
            formState.user.customercode = e.CustomerCode;
            formState.user.customername = e.CustomerName;
            formState.user.customerlastname = e.CustomerLastName;
            formState.user.customerphone = e.CustomerContractNumber;
            formState.user.customerbirthday = e.CustomerBirthDay;
            formState.user.customeremail = e.CustomerEmail;
            formState.user.addressdesc = e.CustomerAddress;
            formState.user.introduction = e.CustomerDesc;
            formState.user.ProvinceID = e.ProvinceID;
            formState.user.ProvinceName = e.ProvinceName;
            formState.user.BorderID = e.BorderID;
            formState.user.BorderName = e.BorderName;
            formState.user.BorderID = e.DistrictId;
            formState.user.DistrictID = e.DistrictID;
            formState.user.DistrictName = e.DistrictName;
            formState.user.PostCode = e.PostCode;
            formState.user.customerlineid = e.LineID;
            formState.user.customerfacebook = e.Facebook;
            formState.user.contactname = e.ContactName;
            formState.user.contact = e.Contact;
            formState.user.customergender =
                e.CustomerGender != undefined
                    ? e.CustomerGender.replace(/\s/g, "")
                    : "notspecified";
            formState.user.customernickname = e.CustomerNickName;
            formState.user.customerbirthdaytype = e.CustomerBirthDayType;
            formState.user.customercareer = e.CustomerCareer;
            formState.user.customeraddressType = e.CustomerAddressType;
            formState.user.selectcountry.selectcountry = e.SelectCountry;
            formState.user.selectcountry.selectcountrycode =
                e.SelectCountryCode;
            formState.user.customerphoneemergency = e.CustomerPhoneEmergency;
            formState.user.customerphonethai = e.CustomerPhoneThai;
            formState.user.contactimageurl = e.ContactImageUrl;

            update.value = update.value + 1;
            console.log(e);
            calculateAge(formState.user.customerbirthday);

            setTimeout(() => {
                if (formState.user.selectcountry.selectcountry) {
                    listcodephone.value.forEach(element => {
                        if (
                            element.value ==
                            formState.user.selectcountry.selectcountrycode
                        ) {
                            formState.user.selectcountry.selectcountrycodenumber =
                                element.label;
                        }
                    });
                }
            }, 3000);
        };
        // const clearfil = e => {
        //     formState.user.contact = "";
        //     formState.user.customercode = e.CustomerCode;
        //     formState.user.customername = "";
        //     formState.user.customerlastname = "";
        //     formState.user.customerphone = "";
        //     formState.user.customerbirthday = "";
        //     formState.user.customeremail = "";
        //     formState.user.addressdesc = "";
        //     formState.user.introduction = "";
        //     formState.user.ProvinceID = "";
        //     formState.user.ProvinceName = "";
        //     formState.user.BorderID = "";
        //     formState.user.BorderName = "";
        //     formState.user.BorderID = "";
        //     formState.user.DistrictName = "";
        //     formState.user.PostCode = "";
        //     formState.user.customerlineid = "";
        //     formState.user.customerfacebook = "";
        //     formState.user.contactname = "";
        //     formState.user.contact = "";
        //     // ImageURL: "",
        //     formState.user.customergender = "notspecified";
        //     formState.user.customernickname = "";
        //     formState.user.customerbirthdaytype = "";
        //     formState.user.customercareer = "";
        //     formState.user.customeraddressType = "1";
        //     formState.user.customerphoneemergency = "";
        //     formState.user.customerphonethai = "";
        //     formState.user.selectcountry.selectcountry = "";
        //     update.value = update.value + 1;
        // };

        const onFinish = async values => {
            // progress.value = true;

            insertdatabase();
            console.log("DATA >> ", values);
            console.log("Success:", values);
        };
        const warning = message => {
            notification["warning"]({
                message: message
            });
        };
        const checkcondition = async () => {
            console.log("checkcondition", formState.user.contactList);
            const result = formState.user.contactList.some(
                item => item.contactstartdefault == "true"
            );
            if (result) {
                return true;
            } else {
                warning("กรุณาตั้งค่าเริ่มต้นช่องทางการติดต่อ");
                return false;
            }
        };

        const insertdatabase = async () => {
            if (checkcondition()) {
                Storedata.state.loading.ishow = true;
                await insertcontract();
                Storedata.state.loading.percent += 14.28;
                await insertcustomer();
                Storedata.state.loading.percent += 14.28;
                await insertdeliveryinside();
                Storedata.state.loading.percent += 14.28;
                await insertdeliveryoutside();
                Storedata.state.loading.percent += 14.28;
                await insertproduct();
                Storedata.state.loading.percent += 14.28;
                await insertproductdeliveryaddress();
                Storedata.state.loading.percent += 14.28;
                await insertpayment();
                Storedata.state.loading.percent += 14.28;
                // await this.insertcreatecustomer();
                // await this.insertcreateproduct();
                // await this.insertcreatedeliveryaddress();
                // await this.insertcreatepayment();

                setTimeout(() => {
                    Storedata.state.loading.ishow = false;
                    success();
                }, 2000);
            }
        };
        const insertcontract = async () => {
            let params = [];
            await formState.user.contactList.forEach((element, index) => {
                element.productsellorder = formState.user.sellorderTransaction;
                element.customerorder = formState.user.customercode;
                element.key = (index + 1).toString();
                var obj = new ContactModel(element);
                console.log("obj", obj);
                params.push(obj);
            });
            console.log("param", params);

            // let slip_url = [];
            var index_uploadimage = 0;
            for await (let items of params) {
                if (items.ContactImage[0]?.preview != undefined) {
                    var uploadparam = {};
                    uploadparam = {
                        filename:
                            formState.user.customercode +
                            "_" +
                            index_uploadimage +
                            ".jpg",
                        base64: items.ContactImage[0].preview,
                        folder: "contact"
                    };
                    await BzbsUser.apiuploadimage(uploadparam)
                        .then(res => {
                            params[index_uploadimage]["ContactImage"] = "";
                            params[index_uploadimage]["ContactImageUrl"] = "";
                            params[index_uploadimage]["ContactImage"] =
                                uploadparam.filename;
                            params[index_uploadimage]["ContactImageUrl"] =
                                res.data.url;
                            console.log(res.data);
                            index_uploadimage++;
                        })
                        .catch(error => {
                            console.log(error);
                        });
                } else {
                    index_uploadimage++;
                }
            }

            for await (let obj of params) {
                await BzbsUser.apiupdatecontact(obj)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertcustomer = async () => {
            let params = new CustomerModel(formState.user);

            console.log("param", params);

            await BzbsUser.apiUpdateCustomer(params)
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
            // }
        };
        const insertdeliveryinside = async () => {
            //insert inside จัดส่ง ในประเทศ
            if (formState.inside.length <= 0) return;
            // var inside = formState.inside;
            // var index_inside = 0;
            // for await (let items of inside) {
            //     if (items.slipimage[0].preview != undefined) {
            //         var uploadparam_inside = {};
            //         uploadparam_inside = {
            //             filename:
            //                 formState.user.customercode +
            //                 "_" +
            //                 index_inside +
            //                 ".jpg",
            //             base64: items.slipimage[0].preview,
            //             folder: "inside"
            //         };
            //         await BzbsUser.apiuploadimage(uploadparam_inside)
            //             .then(res => {
            //                 formState.inside[index_inside]["slipiurl"] = "";
            //                 formState.inside[index_inside]["slipiurl"] =
            //                     res.data.url;
            //                 console.log(res.data);
            //                 index_inside++;
            //             })
            //             .catch(error => {
            //                 console.log(error);
            //             });
            //     }
            // }
            for await (let inside_obj of formState.listproduct) {
                if (inside_obj.inside != undefined) {
                    for await (let items of inside_obj.inside) {
                        var param_inside = {
                            ProductSellOrder:
                                formState.user.sellorderTransaction,
                            CustomerOrder: formState.user.customercode,
                            DeliveryType: "inside",
                            Price: items.price,
                            Id: _.toString(items.id),
                            Introduction: items.introduction,
                            SlipImageURL: items.slipiurl,
                            ProductKey: inside_obj.productkey
                        };
                        await BzbsUser.apicreatedelivery(param_inside)
                            .then(res => {
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
        };
        const insertdeliveryoutside = async () => {
            //insert outside จัดส่ง ในประเทศ
            if (formState.outside.length <= 0) return;
            // var outside = formState.outside;
            // var index_outside = 0;
            // for await (let items of outside) {
            //     if (items.slipimage[0].preview != undefined) {
            //         var uploadparam_outside = {};
            //         uploadparam_outside = {
            //             filename:
            //                 formState.user.customercode +
            //                 "_" +
            //                 index_outside +
            //                 ".jpg",
            //             base64: items.slipimage[0].preview,
            //             folder: "outside"
            //         };
            //         await BzbsUser.apiuploadimage(uploadparam_outside)
            //             .then(res => {
            //                 formState.outside[index_outside]["slipiurl"] = "";
            //                 formState.outside[index_outside]["slipiurl"] =
            //                     res.data.url;
            //                 console.log(res.data);
            //                 index_outside++;
            //             })
            //             .catch(error => {
            //                 console.log(error);
            //             });
            //     }
            // }
            for await (let outside_obj of formState.listproduct) {
                if (outside_obj.inside != undefined) {
                    for await (let items of outside_obj.outside) {
                        var param_outside = {
                            ProductSellOrder:
                                formState.user.sellorderTransaction,
                            CustomerOrder: formState.user.customercode,
                            DeliveryType: "outside",
                            Price: items.price,
                            Id: _.toString(items.id),
                            Introduction: items.introduction,
                            SlipImageURL: items.slipiurl
                        };
                        await BzbsUser.apiupdateproductdelivery(param_outside)
                            .then(res => {
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
        };
        const insertproduct = async () => {
            console.log("insert :", formState.listproduct);

             
            for await (let listproduct_items of formState.listproduct) {
                if (listproduct_items.productyanimage != undefined) {
                    var queyanimage = listproduct_items.productyanimage;
                    for await (let items of queyanimage) {
                        if (items.preview != undefined) {
                            var uploadparam = {};
                            uploadparam = {
                                filename:
                                    formState.user.customercode +
                                    "_" +
                                    Math.floor(Date.now() / 1000) +
                                    ".jpg",
                                base64: items.preview,
                                folder: "queyan"
                            };
                            await BzbsUser.apiuploadimage(uploadparam)
                                .then(res => {
                                    listproduct_items["productyanimage"] = "";
                                    listproduct_items["productyanimage"] =
                                        res.data.url;
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }
                listproduct_items.productsellorder =
                    formState.user.sellorderTransaction;
                listproduct_items.customerorder = formState.user.customercode;
                listproduct_items.OrderDate = formState.user.orderdate;

                let params = new ProductModel(listproduct_items);

                console.log("param", params);

                await BzbsUser.apiupdateproduct(params)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertproductdeliveryaddress = async () => {
            for await (let listproductdeliveryaddress_items of formState.user
                .addressfrom) {
                // if (listproduct_items.productyanimage != undefined) {
                //     var queyanimage = listproduct_items.productyanimage;
                //     for await (let items of queyanimage) {
                //         if (items.preview != undefined) {
                //             var uploadparam = {};
                //             uploadparam = {
                //                 filename: formState.user.customercode + ".jpg",
                //                 base64: items.preview,
                //                 folder: "queyan"
                //             };
                //             await BzbsUser.apiuploadimage(uploadparam)
                //                 .then(res => {
                //                     listproduct_items["productyanimage"] = "";
                //                     listproduct_items["productyanimage"] =
                //                         res.data.url;
                //                     console.log(res.data);
                //                 })
                //                 .catch(error => {
                //                     console.log(error);
                //                 });
                //         }
                //     }
                // }

                listproductdeliveryaddress_items.productsellorder =
                    formState.user.sellorderTransaction;
                listproductdeliveryaddress_items.customerorder =
                    formState.user.customercode;

                var phonecode = "";
                if (listproductdeliveryaddress_items?.phonecode) {
                    phonecode = listproductdeliveryaddress_items?.phonecode;
                } else if (
                    listproductdeliveryaddress_items.phonecode &&
                    listproductdeliveryaddress_items.phonecode[0].label
                ) {
                    phonecode =
                        listproductdeliveryaddress_items.phonecode[0].label;
                }
                if (phonecode) {
                    listproductdeliveryaddress_items.phonecode = phonecode;
                }
                let params = new ProductDeliveryAddressModel(
                    listproductdeliveryaddress_items
                );

                console.log("param", params);

                await BzbsUser.apiupdateproductdelivery(params)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertpayment = async () => {
            var indeximage = 0;
            if (formState.pay.slipimage != undefined) {
                for await (let items of formState.pay.slipimage) {
                    indeximage++;
                    if (items.preview != undefined) {
                        var uploadparam = {};
                        uploadparam = {
                            filename:
                                formState.user.customercode +
                                "_" +
                                indeximage +
                                "_" +
                                Date.now() +
                                ".jpg",
                            base64: items.preview,
                            folder: "payment"
                        };
                        await BzbsUser.apiuploadimage(uploadparam)
                            .then(res => {
                                formState.pay["slipimageurl"] = "";
                                formState.pay["slipimageurl"] = res.data.url;
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
            formState.pay.productsellorder =
                formState.user.sellorderTransaction;
            formState.pay.customerorder = formState.user.customercode;

            let params = new PaymentModel(formState.pay);
            console.log("param", params);
            await BzbsUser.apipayment(params)
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const isshowDetailSellOrder = reactive({
            isshow: false,
            sellorder: ""
        });

        const showdetialselectedRowKeys = e => {
            // formState.user.sellorderTransaction = e
            isshowDetailSellOrder.isshow = true;
            isshowDetailSellOrder.sellorder = e;
            console.log("sellorder:", e);
        };
        const showdetialselectedsellorder = e => {
            formState.user.sellorderTransaction = e;
            isshowDetailSellOrder.isshow = true;
            isshowDetailSellOrder.sellorder = e;
            console.log("sellorder:", e);
        };

        const clickblack = () => {
            rowselecteddetail.ishowdetail = false;
        };

        const acceptNumber = () => {
            var x = formState.user.customerphone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            formState.user.customerphone = !x[2]
                ? x[1]
                : x[1] + x[2] + (x[3] ? x[3] : "");
        };

        const checkGrantProductListByCustomerCode = () => {
            if (route.path == "/delivery") {
                return false;
            } else {
                return true;
            }
        };
        const isshowpackproduct = ref(false);
        const isClickSubmit = () => {
            isshowpackproduct.value = true;
            if (
                route.path == "/createdeliverylist" &&
                formStateStore.btnsubmit == "ยืนยันการจัดส่ง"
            ) {
                formStateStore.btnsubmit = "สร้าง Tracking No.";
            } else if (
                route.path == "/createdeliverylist" &&
                formStateStore.btnsubmit == "ยืนยันสินค้าจัดส่ง"
            ) {
                formStateStore.btnsubmit = "ยืนยันการจัดส่ง";
            }
        };

        const setshowpopup = e => {
            isshowcanceldelivery.value = e;
        };
        const getcodephone = code => {
            return listcodephone.value.find(element => element.value == code)
                ?.label;
        };
        const gotodetail = () => {
            //window.location = webpath + '/selllist?customercode=' + formState.user.customercode + '&issshowdetail=true'
        };

        const handleOk_export_rang = () => {
            if (current_export_type.value == "red") export_report_red_more();
        };
        const export_report_red_more = async () => {
            visible_export_rang.value = false;
            if (export_criteria.start != "" && export_criteria.end != "") {
                var temp_listcreatedelivery = formState.listcreatedelivery.slice(
                    export_criteria.value.start - 1,
                    export_criteria.value.end
                );
                var userdetail = Account?.getCacheUserBackoffice();

                var list_products = [];
                var birtdaydetail = [];

                var list_OrderTransaction = uniqBy(
                    temp_listcreatedelivery,
                    obj => obj.OrderTransaction
                );
                var OrderTransaction_text = "";
                var OrderTransaction = [];
                var OrderTransaction_change_size = [];
                var listpaymentbyso = {};

                var index = 0;

                for (const element of list_OrderTransaction) {
                    listpaymentbyso = {};

                    await BzbsUser.apigetpaymentbyso(
                        element.PD_ProductSellOrder
                    )
                        .then(response => {
                            listpaymentbyso = {
                                productsellorder: element.PD_ProductSellOrder,
                                results: response.data
                            };

                            console.log(listpaymentbyso);
                        })
                        .catch(error => {
                            console.log(error);
                        });

                    if (element.Category_2 != "เพิ่มขนาดยังไม่ได้ลงระบบ") {
                        console.log(
                            `OrderTransaction : ${element.OrderTransaction} index : ${index}`
                        );
                        if (index === list_OrderTransaction.length - 1) {
                            OrderTransaction.push(element.OrderTransaction);
                        } else {
                            OrderTransaction.push(element.OrderTransaction);
                        }

                        index++;

                        await BzbsUser.apigetarraytran(element.OrderTransaction)
                            .then(response => {
                                if (response?.data?.resultot?.length > 0) {
                                    OrderTransaction = OrderTransaction.filter(
                                        e => e !== element.OrderTransaction
                                    );
                                }
                                if (response?.data?.resultot?.length > 0) {
                                    // OrderTransaction += '\nลำดับเพิ่มขนาด '
                                    response?.data?.resultot
                                        ?.reverse()
                                        ?.forEach((element, indexs) => {
                                            if (
                                                indexs ===
                                                response?.data?.resultot
                                                    ?.length -
                                                    1
                                            ) {
                                                OrderTransaction_change_size.push(
                                                    element
                                                );
                                            } else {
                                                OrderTransaction_change_size.push(
                                                    element
                                                );
                                            }
                                        });
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }

                console.log("OrderTransaction :", OrderTransaction);
                console.log(
                    "OrderTransaction_change_size :", 
                    OrderTransaction_change_size
                );

                var Result_OrderTransaction_change_size = OrderTransaction_change_size?.map(
                    Number
                ).join("/");

                if (Result_OrderTransaction_change_size) {
                    OrderTransaction.push(Result_OrderTransaction_change_size);
                }

                console.log(
                    "Result_OrderTransaction_change_size : ",
                    Result_OrderTransaction_change_size
                );
                var OrderDate = "";
                var PD_TrackingNo = "";
                temp_listcreatedelivery.forEach((item, item_index) => {
                    // OrderTransaction = item.OrderTransaction ? item.OrderTransaction : OrderTransaction
                    OrderDate = item.OrderDate ? item.OrderDate : OrderDate;
                    PD_TrackingNo = item.PD_TrackingNo
                        ? item.PD_TrackingNo
                        : PD_TrackingNo;
                    console.log(
                        "formStateStore.reportred.mockData : ",
                        formStateStore.reportred.mockData
                    );

                    formStateStore.reportred.mockData.forEach(
                        (element, index) => {
                            if (
                                element.Category_2 == "เพิ่มขนาดยังไม่ได้ลงระบบ"
                            )
                                return;

                            if (item.key == element.key) {
                                birtdaydetail = [];
                                if (element.detail) {
                                    element.detail.forEach(element => {
                                        var d = "";
                                        if (element.typebirthday == "3") {
                                            d = moment(element.birthday).format(
                                                "l"
                                            );
                                        } else if (
                                            element.typebirthday == "2"
                                        ) {
                                            d = moment(element.birthday)
                                                .add(543, "year")
                                                .format("l");
                                        }
                                        if (d != "Invalid date")
                                            birtdaydetail.push("(" + d + ")");
                                    });
                                }

                                var deliveryprice_AdditionalShippingas =
                                    parseFloat(item.PD_AdditionalShippingas) ||
                                    0;
                                var deliveryprice =
                                    parseFloat(item.PD_Price) || 0;

                                var listdesc = [];
                                if (
                                    element.ProfessorType &&
                                    JSON.parse(element.ProfessorType)?.length >
                                        0
                                ) {
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("1")
                                    ) {
                                        listdesc.push("อาจารย์เลือกให้");
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("2")
                                    ) {
                                        listdesc.push("รอคุยกับอาจารย์");
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("4")
                                    ) {
                                        listdesc.push("ส่งเลย");
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("6")
                                    ) {
                                        listdesc.push("จัดส่งพร้อมยันต์");
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("7")
                                    ) {
                                        listdesc.push("จัดส่งทางไลน์");
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("8")
                                    ) {
                                        listdesc.push("รับเอง");
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("9")
                                    ) {
                                        listdesc.push("เก็บเงินปลายทาง");
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("3")
                                    ) {
                                        listdesc.push("พรีออเดอร์");
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("5")
                                    ) {
                                        listdesc.push("ขอรูปคู่อาจารย์");
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("10")
                                    ) {
                                        listdesc.push(
                                            "มัดจำแล้วรอคุยกับอาจารย์"
                                        );
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("11")
                                    ) {
                                        listdesc.push("เพิ่มขนาดไม่ทัน");
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("12")
                                    ) {
                                        listdesc.push(
                                            "เพิ่มขนาดโดยใช้แทร็คใหม่"
                                        );
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("13")
                                    ) {
                                        listdesc.push("ย้ายสินค้าเข้าสต๊อก");
                                    }
                                    if (
                                        JSON.parse(
                                            element.ProfessorType
                                        ).includes("14")
                                    ) {
                                        listdesc.push("อาจารย์ออกแบบโลโก้");
                                    }
                                }

                                var checked_pay_extra_price = 0;
                                var pay_price_already = 0;
                                var list_paid = {
                                    checked_pay_extra: [],
                                    pay_price_already: []
                                };

                                formState.pay?.listpay?.forEach(
                                    async (element, index) => {
                                        if (element.checked_pay_extra == true) {
                                            checked_pay_extra_price +=
                                                element.price;
                                            list_paid.checked_pay_extra.push(
                                                element.price
                                            );
                                        } else {
                                            pay_price_already += element.price;
                                            list_paid.pay_price_already.push(
                                                element.price
                                            );
                                        }
                                    }
                                ); 
                                console.log("2") 
                                var products = {
                                    id: index + 1,
                                    name: element.description,
                                    birthday: birtdaydetail?.toString(),
                                    company: item.CustomerProductsType,
                                    comingyanimage:item.ComingYanImage||getimageproduct(item.ProductDetail),
                                    unitPrice: item.ProductPrice,
                                    totalPrice:
                                        item.ProductPrice * (item.Qty || 1),
                                    qty: item.QTYProduct || 1,
                                    PD_Price: deliveryprice,
                                    Deliveryprice_AdditionalShippingas: deliveryprice_AdditionalShippingas,
                                    PD_DeliveryType: element.PD_DeliveryType,
                                    introduction: element.introduction,
                                    list_paid: list_paid,
                                    listpaymentbyso: listpaymentbyso,
                                    desc: listdesc
                                };

                                list_products.push(products);
                            }
                        }
                    );
                });
   
                var result_list_products = [];
                list_products.forEach(function(a, index) {
                    var space_track = "";
                    if (a.birthday) {
                        space_track = index == 0 ? "" : "";
                    }
                    if (
                        ["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน"].includes(a.company)
                    ) {
                        this[a.name] = {
                            id: a.id,
                            name: a.name,
                            birthday: a.birthday,
                            company: a.company,
                            comingyanimage:a.ComingYanImage,
                            unitPrice: a.unitPrice,
                            totalPrice: a.totalPrice,
                            qty: a.qty,
                            PD_Price: a.PD_Price,
                            Deliveryprice_AdditionalShippingas:
                                a.Deliveryprice_AdditionalShippingas,
                            PD_DeliveryType: a.PD_DeliveryType,
                            desc: a.desc,
                            introduction: a.introduction,
                            list_paid: a.list_paid,
                            listpaymentbyso: a.listpaymentbyso
                        };
                        result_list_products.push(this[a.name]);
                    } else if (a.introduction) {
                        this[a.name] = {
                            id: a.id,
                            logchangesize: a.logchangesize,
                            name: a.name,
                            birthday: "",
                            company: a.company,
                            comingyanimage:a.ComingYanImage,
                            unitPrice: a.unitPrice,
                            totalPrice: a.totalPrice,
                            qty: a.qty,
                            PD_Price: a.PD_Price,
                            PD_DeliveryType: a.PD_DeliveryType,
                            DeliveryType: a.DeliveryType,
                            Paid_Change_Size: a.Paid_Change_Size,
                            increasesizedesc: a.increasesizedesc,
                            increasesizeorder: a.increasesizeorder,
                            log_change_old_unitprice:
                                a.log_change_old_unitprice,
                            checked_pay_extra_price: a.checked_pay_extra_price,
                            arrearage: a.arrearage,
                            desc: a.desc,
                            list_paid: a.list_paid,
                            introduction: a.introduction,
                            list_paid: a.list_paid,
                            listpaymentbyso: a.listpaymentbyso
                        };
                        result_list_products.push(this[a.name]);
                        this[a.name].birthday += space_track + a.birthday;
                    } else {
                        if (!this[a.name]) {
                            this[a.name] = {
                                id: a.id,
                                name: a.name,
                                birthday: "",
                                company: a.company,
                                comingyanimage:a.ComingYanImage,
                                unitPrice: a.unitPrice,
                                totalPrice: 0,
                                qty: 0,
                                PD_Price: a.PD_Price,
                                Deliveryprice_AdditionalShippingas:
                                    a.Deliveryprice_AdditionalShippingas,
                                PD_DeliveryType: a.PD_DeliveryType,
                                desc: a.desc,
                                introduction: a.introduction,
                                list_paid: a.list_paid,
                                listpaymentbyso: a.listpaymentbyso
                            };
                            result_list_products.push(this[a.name]);
                        } else {
                            result_list_products?.forEach((item, index) => {
                                if (item?.name == a?.name) {
                                    result_list_products[index].PD_Price =
                                        result_list_products[index]?.PD_Price +
                                        a?.PD_Price;
                                }
                            });
                        }
                        this[a.name].totalPrice += a.totalPrice;
                        this[a.name].qty += a.qty;
                        // this[a.name].PD_Price += a.PD_Price;
                        this[a.name].birthday += space_track + a.birthday;
                    }
                }, Object.create(null));

                console.log("result_list_products: ", result_list_products);

                var phonecode = "";
                var country = "";
                if (formState.user.customeraddressType != "1") {
                    phonecode = formState.user.selectcountry
                        .selectcountrycodenumber
                        ? formState.user.selectcountry.selectcountrycodenumber
                        : "";
                    country = formState.user.selectcountry.selectcountry;
                }
                var trackyan = [];

                temp_listcreatedelivery.forEach(element => {
                    if (element.TrackYan) trackyan.push(element.TrackYan);
                });

                let customerInfo = {
                    customerCode: formState.user.customercode,
                    ContactName: formState.user.contactList?.map(item => {
                        if (item.contactstartdefault == "true") {
                            return item.contactname?.toString();
                        }
                    }),
                    customerName:
                        (clearSquareSymbols(formState.user.customername) ||
                            "") +
                        " " +
                        (clearSquareSymbols(formState.user.customerlastname) ||
                            ""),
                    address: formState.user.addressdesc,
                    city: formState.user.ProvinceName,
                    state: formState.user.BorderName,
                    pincode: formState.user.PostCode,
                    country: country,
                    contactNo:
                        phonecode + " " + formState.user.customerphonethai,
                    addresslist: formState.user.addressalllist,
                    trackyanlist: trackyan?.toString(),
                    switchnothasphonecode:
                        formState.useraddress.switchnothasphonecode
                };

                await BzbsUser.apigetpaymentdetail(
                    formState.user.customercode,
                    "normal",
                    formState.user.sellorderTransaction
                )
                    .then(res => {
                        res.data[0] = _.transform(res.data[0], function(
                            result,
                            val,
                            key
                        ) {
                            result[key.toLowerCase()] = val;
                        });
                        // value.value = res.data[0].paymentmethod;
                        let resultobj = Object.assign(
                            formState.pay,
                            res.data[0]
                        );
                        if (res.data[0].date) {
                            resultobj.date =
                                ref(dayjs(res.data[0].date, "YYYY-MM-DD")) ||
                                "";
                        }
                        if (res.data[0].time) {
                            resultobj.time =
                                ref(dayjs(res.data[0].time, "HH:mm")) || "";
                        }
                        formState.pay = resultobj;
                        // isinited.value = true;
                        return console.log(res.data[0]);
                    })
                    .catch(error => {
                        console.log(error);
                    });

                var bankarray = [];

                if (formState.pay) {
                    bankarray.push(formState.pay);
                }
                // var listfrombank = []
                // if (formState.pay.length) {
                //     formState.pay?.forEach(element => {
                //         if (element.frombank)
                //             listfrombank.push(element.frombank)
                //         else if (element.bankname)
                //             listfrombank.push(element.bankname)

                //         var itembank = { ...element }

                //     });
                // }
 
                let orderInfo = {
                    trackingno: PD_TrackingNo,
                    orderNo: OrderTransaction?.toString(),
                    orderDate: moment(OrderDate)
                        .add(543, "year")
                        .format("l"),
                    currentdate: moment()
                        .add(543, "year")
                        .format("l"),
                    accountname: "",
                    frombank: "",
                    tobank: "",
                    products: Utility.toJsonStr(result_list_products),
                    totalValue: 0,
                    sp: formState.rowselecteddetail,
                    bankarray: bankarray
                };
                var exportreport = {
                    customerInfo: customerInfo,
                    orderInfo: orderInfo,
                    userdetail: userdetail,
                    start: export_criteria.value.start,
                    end: export_criteria.value.end
                };

                console.log(exportreport);

                await BzbsUser.apireportred(exportreport)
                    .then(response => {
                        let blob = new Blob([response.data], {
                            type: "application/pdf"
                        });
                        let link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = "ใบรายการจัดส่ง.pdf";
                        link.click();
                        successmessage("โหลดไฟล์สำเร็จ");
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const export_report_red_normal = async () => {
            var userdetail = Account?.getCacheUserBackoffice();

            var list_products = [];
            var birtdaydetail = [];

            var list_OrderTransaction = uniqBy(
                formStateStore.listcreatedelivery,
                obj => obj.OrderTransaction
            );
            var OrderTransaction_text = "";
            var OrderTransaction = [];
            var OrderTransaction_change_size = [];
            var listpaymentbyso = {};

            var index = 0;

            for (const element of list_OrderTransaction) {
                listpaymentbyso = {};

                await BzbsUser.apigetpaymentbyso(element.PD_ProductSellOrder)
                    .then(response => {
                        listpaymentbyso = {
                            productsellorder: element.PD_ProductSellOrder,
                            results: response.data
                        };

                        console.log(listpaymentbyso);
                    })
                    .catch(error => {
                        console.log(error);
                    });

                if (element.Category_2 != "เพิ่มขนาดยังไม่ได้ลงระบบ") {
                    console.log(
                        `OrderTransaction : ${element.OrderTransaction} index : ${index}`
                    );
                    if (index === list_OrderTransaction.length - 1) {
                        OrderTransaction.push(element.OrderTransaction);
                    } else {
                        OrderTransaction.push(element.OrderTransaction);
                    }

                    index++;

                    await BzbsUser.apigetarraytran(element.OrderTransaction)
                        .then(response => {
                            if (response?.data?.resultot?.length > 0) {
                                OrderTransaction = OrderTransaction.filter(
                                    e => e !== element.OrderTransaction
                                );
                            }
                            if (response?.data?.resultot?.length > 0) {
                                // OrderTransaction += '\nลำดับเพิ่มขนาด '
                                response?.data?.resultot
                                    ?.reverse()
                                    ?.forEach((element, indexs) => {
                                        if (
                                            indexs ===
                                            response?.data?.resultot?.length - 1
                                        ) {
                                            OrderTransaction_change_size.push(
                                                element
                                            );
                                        } else {
                                            OrderTransaction_change_size.push(
                                                element
                                            );
                                        }
                                    });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            }

            console.log("OrderTransaction :", OrderTransaction);
            console.log(
                "OrderTransaction_change_size :",
                OrderTransaction_change_size
            );

            var Result_OrderTransaction_change_size = OrderTransaction_change_size?.map(
                Number
            ).join("/");

            if (Result_OrderTransaction_change_size) {
                OrderTransaction.push(Result_OrderTransaction_change_size);
            }

            console.log(
                "Result_OrderTransaction_change_size : ",
                Result_OrderTransaction_change_size
            );
            var OrderDate = "";
            var PD_TrackingNo = "";

            // var list_listcreatedelivery = formStateStore.listcreatedelivery.filter(
            //     item => item.ProductStatus != "cancel"
            // );
            // console.log(
            //     "list_listcreatedelivery : ",
            //     list_listcreatedelivery
            // );

            var list_listcreatedelivery = formStateStore.listcreatedelivery;
         
            var find_cancel = list_listcreatedelivery.find(
                item => item.ProductStatus == "cancel"
            );

            list_listcreatedelivery = list_listcreatedelivery.filter(
                item => item.ProductStatus != "cancel"
            );
            if (find_cancel) {
                list_listcreatedelivery.forEach((obj, index) => {
                    obj.key = index;
                });
            }
            list_listcreatedelivery.forEach((item, item_index) => {
                if (item.ProductStatus == "cancel") return;
 
                OrderDate = item.OrderDate ? item.OrderDate : OrderDate;
                PD_TrackingNo = item.PD_TrackingNo
                    ? item.PD_TrackingNo
                    : PD_TrackingNo;
                console.log(
                    "formStateStore.reportred.mockData : ",
                    formStateStore.reportred.mockData
                );

                formStateStore.reportred.mockData.forEach((element, index) => {
                    if (element.Category_2 == "เพิ่มขนาดยังไม่ได้ลงระบบ")
                        return;

        
                    if (item.key == element.key) {
                        birtdaydetail = [];
                        if (element.detail) {
                            element.detail.forEach(element => {
                                var d = "";
                                if (element.typebirthday == "3") {
                                    d = moment(element.birthday).format("l");
                                } else if (element.typebirthday == "2") {
                                    d = moment(element.birthday)
                                        .add(543, "year")
                                        .format("l");
                                }
                                if (d != "Invalid date")
                                    birtdaydetail.push("(" + d + ")");
                            });
                        }

                        var deliveryprice_AdditionalShippingas =
                            parseFloat(item.PD_AdditionalShippingas) || 0;
                        var deliveryprice = parseFloat(item.PD_Price) || 0;

                        var listdesc = [];
                        if (
                            element.ProfessorType &&
                            JSON.parse(element.ProfessorType)?.length > 0
                        ) {
                            if (
                                JSON.parse(element.ProfessorType).includes("1")
                            ) {
                                listdesc.push("อาจารย์เลือกให้");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("2")
                            ) {
                                listdesc.push("รอคุยกับอาจารย์");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("4")
                            ) {
                                listdesc.push("ส่งเลย");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("6")
                            ) {
                                listdesc.push("จัดส่งพร้อมยันต์");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("7")
                            ) {
                                listdesc.push("จัดส่งทางไลน์");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("8")
                            ) {
                                listdesc.push("รับเอง");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("9")
                            ) {
                                listdesc.push("เก็บเงินปลายทาง");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("3")
                            ) {
                                listdesc.push("พรีออเดอร์");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("5")
                            ) {
                                listdesc.push("ขอรูปคู่อาจารย์");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("10")
                            ) {
                                listdesc.push("มัดจำแล้วรอคุยกับอาจารย์");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("11")
                            ) {
                                listdesc.push("เพิ่มขนาดไม่ทัน");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("12")
                            ) {
                                listdesc.push("เพิ่มขนาดโดยใช้แทร็คใหม่");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("13")
                            ) {
                                listdesc.push("ย้ายสินค้าเข้าสต๊อก");
                            }
                            if (
                                JSON.parse(element.ProfessorType).includes("14")
                            ) {
                                listdesc.push("อาจารย์ออกแบบโลโก้");
                            }
                        }

                        var checked_pay_extra_price = 0;
                        var pay_price_already = 0;
                        var list_paid = {
                            checked_pay_extra: [],
                            pay_price_already: []
                        };

                        formState.pay?.listpay?.forEach(
                            async (element, index) => {
                                if (element.checked_pay_extra == true) {
                                    checked_pay_extra_price += element.price;
                                    list_paid.checked_pay_extra.push(
                                        element.price
                                    );
                                } else {
                                    pay_price_already += element.price;
                                    list_paid.pay_price_already.push(
                                        element.price
                                    );
                                }
                            }
                        ); 
            console.log("1")
                        var products = {
                            id: index + 1,
                            name: element.description,
                            birthday: birtdaydetail?.toString(),
                            company: item.CustomerProductsType,
                            comingyanimage:item.ComingYanImage||getimageproduct(item.ProductDetail), 
                            unitPrice: item.ProductPrice,
                            totalPrice: item.ProductPrice * (item.Qty || 1),
                            qty: item.QTYProduct || 1,
                            PD_Price: deliveryprice,
                            Deliveryprice_AdditionalShippingas: deliveryprice_AdditionalShippingas,
                            PD_DeliveryType: element.PD_DeliveryType,
                            introduction: element.introduction,
                            list_paid: list_paid,
                            listpaymentbyso: listpaymentbyso,
                            desc: listdesc
                        };

                        list_products.push(products);
                    }
                });
            });


            var result_list_products = [];
            list_products.forEach(function(a, index) {
                var space_track = "";
                if (a.birthday) {
                    space_track = index == 0 ? "" : "";
                }
   
                if (["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน"].includes(a.company)) {
                    this[a.name] = {
                        id: a.id,
                        name: a.name,
                        birthday: a.birthday,
                        company: a.company,
                        comingyanimage:a.comingyanimage,
                        unitPrice: a.unitPrice,
                        totalPrice: a.totalPrice,
                        qty: a.qty,
                        PD_Price: a.PD_Price,
                        Deliveryprice_AdditionalShippingas:
                            a.Deliveryprice_AdditionalShippingas,
                        PD_DeliveryType: a.PD_DeliveryType,
                        desc: a.desc,
                        introduction: a.introduction,
                        list_paid: a.list_paid,
                        listpaymentbyso: a.listpaymentbyso
                    };
                    result_list_products.push(this[a.name]);
                } else if (a.introduction) {
                    this[a.name] = {
                        id: a.id,
                        logchangesize: a.logchangesize,
                        name: a.name,
                        birthday: "",
                        company: a.company,
                        comingyanimage:a.comingyanimage,
                        unitPrice: a.unitPrice,
                        totalPrice: a.totalPrice,
                        qty: a.qty,
                        PD_Price: a.PD_Price,
                        PD_DeliveryType: a.PD_DeliveryType,
                        DeliveryType: a.DeliveryType,
                        Paid_Change_Size: a.Paid_Change_Size,
                        increasesizedesc: a.increasesizedesc,
                        increasesizeorder: a.increasesizeorder,
                        log_change_old_unitprice: a.log_change_old_unitprice,
                        checked_pay_extra_price: a.checked_pay_extra_price,
                        arrearage: a.arrearage,
                        desc: a.desc,
                        list_paid: a.list_paid,
                        introduction: a.introduction,
                        list_paid: a.list_paid,
                        listpaymentbyso: a.listpaymentbyso
                    };
                    result_list_products.push(this[a.name]);
                    this[a.name].birthday += space_track + a.birthday;
                } else {
                    if (!this[a.name]) {
                        this[a.name] = {
                            id: a.id,
                            name: a.name,
                            birthday: "",
                            company: a.company,
                            comingyanimage:a.comingyanimage,
                            unitPrice: a.unitPrice,
                            totalPrice: 0,
                            qty: 0,
                            PD_Price: a.PD_Price,
                            Deliveryprice_AdditionalShippingas:
                                a.Deliveryprice_AdditionalShippingas,
                            PD_DeliveryType: a.PD_DeliveryType,
                            desc: a.desc,
                            introduction: a.introduction,
                            list_paid: a.list_paid,
                            listpaymentbyso: a.listpaymentbyso
                        };
                        result_list_products.push(this[a.name]);
                    } else {
                        result_list_products?.forEach((item, index) => {
                            if (item?.name == a?.name) {
                                result_list_products[index].PD_Price =
                                    result_list_products[index]?.PD_Price +
                                    a?.PD_Price;
                            }
                        });
                    }
                    this[a.name].totalPrice += a.totalPrice;
                    this[a.name].qty += a.qty;
                    // this[a.name].PD_Price += a.PD_Price;
                    this[a.name].birthday += space_track + a.birthday;
                }
            }, Object.create(null));

            console.log("result_list_products: ", result_list_products);

            var phonecode = "";
            var country = "";
            if (formState.user.customeraddressType != "1") {
                phonecode = formState.user.selectcountry.selectcountrycodenumber
                    ? formState.user.selectcountry.selectcountrycodenumber
                    : "";
                country = formState.user.selectcountry.selectcountry;
            }
            var trackyan = [];

            formState.listcreatedelivery.forEach(element => {
                if (element.TrackYan && element.ProductStatus != "cancel") {
                    trackyan.push(element.TrackYan);
                }
            });

            let customerInfo = {
                customerCode: formState.user.customercode,
                ContactName: formState.user.contactList?.map(item => {
                    if (item.contactstartdefault == "true") {
                        return item.contactname?.toString();
                    }
                }),
                customerName:
                    (clearSquareSymbols(formState.user.customername) || "") +
                    " " +
                    (clearSquareSymbols(formState.user.customerlastname) || ""),
                address: formState.user.addressdesc,
                city: formState.user.ProvinceName,
                state: formState.user.BorderName,
                pincode: formState.user.PostCode,
                country: country,
                contactNo: phonecode + " " + formState.user.customerphonethai,
                addresslist: formState.user.addressalllist,
                trackyanlist: trackyan?.toString(),
                switchnothasphonecode:
                    formState.useraddress.switchnothasphonecode
            };

            await BzbsUser.apigetpaymentdetail(
                formState.user.customercode,
                "normal",
                formState.user.sellorderTransaction
            )
                .then(res => {
                    res.data[0] = _.transform(res.data[0], function(
                        result,
                        val,
                        key
                    ) {
                        result[key.toLowerCase()] = val;
                    });
                    // value.value = res.data[0].paymentmethod;
                    let resultobj = Object.assign(formState.pay, res.data[0]);
                    if (res.data[0].date) {
                        resultobj.date =
                            ref(dayjs(res.data[0].date, "YYYY-MM-DD")) || "";
                    }
                    if (res.data[0].time) {
                        resultobj.time =
                            ref(dayjs(res.data[0].time, "HH:mm")) || "";
                    }
                    formState.pay = resultobj;
                    // isinited.value = true;
                    return console.log(res.data[0]);
                })
                .catch(error => {
                    console.log(error);
                });

            var bankarray = [];

            if (formState.pay) {
                bankarray.push(formState.pay);
            }
            // var listfrombank = []
            // if (formState.pay.length) {
            //     formState.pay?.forEach(element => {
            //         if (element.frombank)
            //             listfrombank.push(element.frombank)
            //         else if (element.bankname)
            //             listfrombank.push(element.bankname)

            //         var itembank = { ...element }

            //     });
            // }
            
            let orderInfo = {
                trackingno: PD_TrackingNo,
                orderNo: OrderTransaction?.toString(),
                orderDate: moment(OrderDate)
                    .add(543, "year")
                    .format("l"),
                currentdate: moment()
                    .add(543, "year")
                    .format("l"),
                accountname: "",
                frombank: "",
                tobank: "",
                products: Utility.toJsonStr(result_list_products),
                totalValue: 0,
                sp: formState.rowselecteddetail,
                bankarray: bankarray
            };
            var exportreport = {
                customerInfo: customerInfo,
                orderInfo: orderInfo,
                userdetail: userdetail
            };

            console.log(exportreport);
        
            await BzbsUser.apireportred(exportreport)
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: "application/pdf"
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "ใบรายการจัดส่ง.pdf";
                    link.click();
                    successmessage("โหลดไฟล์สำเร็จ");
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const getimageproduct =(ProductDetail)=>{
            try {
                return JSON.parse(JSON.parse(ProductDetail)?.productimage)[0]
            } catch (error) {
                return ''
            }
           

        }
        const reportsettingred = async () => {
            current_export_type.value = "red";
            if (formState.listcreatedelivery.length > 7) {
                visible_export_rang.value = true;
            } else {
                export_report_red_normal();
            }
        };
        const successmessage = message => {
            Modal.success({
                title: message,
                onOk() {}
            });
        };
        const onaddresschange = async () => {
            isshowsentaddress.value = false;
            await getproductdeliveryaddress();
            await gencodecustomer();
            await getdeliveryaddress();

            updatecomponent.value++;
        };

        const visibleaddress = ref(false);
        const showModaladdress = () => {
            visibleaddress.value = true;
        };
        const handleOkaddress = e => {
            console.log(e);
            visibleaddress.value = false;

            setTimeout(async () => {
                await getproductdeliveryaddress();
                await gencodecustomer();
                await getdeliveryaddress();
                updatecomponent.value++;
            }, 2000);
        };
        const ishastrack = ref(false);
        const is_ProfessorType_7_8_9 = ref(false);
        const checkhastrack = () => {
            formState.listcreatedelivery.forEach(element => {
                var ProfessorType = element.ProfessorType
                    ? JSON.parse(element.ProfessorType)
                    : null;

                if (element.PD_TrackingNo) {
                    ishastrack.value = true;
                    console.log(
                        "element.PD_TrackingNo : ",
                        element.PD_TrackingNo
                    );
                }

                if (
                    ProfessorType.includes("7") ||
                    ProfessorType.includes("8") ||
                    ProfessorType.includes("9") ||
                    element.DeliveryType.includes("12") ||
                    element.DeliveryType.includes("14")
                ) {
                    console.log(
                        "element.DeliveryType : ",
                        element.DeliveryType
                    );
                    ishastrack.value = true;
                    is_ProfessorType_7_8_9.value = true;
                }
            });
        };
        const clearSquareSymbols = word => {
            const regex = /[^a-zA-Z0-9@#*\u0E00-\u0E7F/.]/g;
            return word.replace(regex, " ");
        };
        setTimeout(() => {
            checkhastrack();
        }, 1000);
        return {
            handleOk_export_rang,
            visible_export_rang,
            export_criteria,
            clearSquareSymbols,
            is_ProfessorType_7_8_9,
            ishastrack,
            visibleaddress,
            showModaladdress,
            handleOkaddress,
            onaddresschange,
            isshowcustomerdescproduct,
            reportsettingred,
            gotodetail,
            isshowsentaddress,
            getcodephone,
            isshowcanceldelivery,
            setshowpopup,
            updatecomponent,
            isshowpackproduct,
            isClickSubmit,
            checkGrantProductListByCustomerCode,
            acceptNumber,
            clickblack,
            isshowDetailSellOrder,
            showdetialselectedRowKeys,
            showdetialselectedsellorder,
            insertproduct,
            insertproductdeliveryaddress,
            gencodecustomer,
            title,
            formState,
            onFinish,
            layout,
            validateMessages,
            SellOrderTransaction,
            onselectchange,
            calculateAge,
            options,
            listcodephone,
            handleChange,
            handleBlur,
            handleFocus,
            filterOption,
            onFinishFailed,
            queryProductType,
            producttype,
            onModalAddressChagne,
            publishedBooksMessage,
            getallproduct,
            success,
            imageslip,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            calculateAge_setting,
            datecreatetoday,
            typelocal,
            age,
            update,
            insertdatabase,
            insertcontract,
            insertcustomer,
            rowselecteddetail,
            getCustomer,
            formStateStore,
            Storedata,
            errorInfo: FormHelper.genErrorInfo(7),
            datetest: ref(new Date()),
            updatetracktable,
            pdfOptions: {
                margin: 10,
                image: {
                    type: "jpeg",
                    quality: 1
                },
                html2canvas: { scale: 3 },
                jsPDF: {
                    unit: "mm",
                    format: "a4",
                    orientation: "l"
                }
            },
            exportFilename: new Date() + "print.pdf"
        };
    }
    // computed: {
    //     createproduct: function() {
    //         return Storedata.state.createproduct;
    //     }
    // }
});
</script>
<style lang="scss" scoped>
.ant-row {
    flex-direction: row;
}

.date_create {
    display: flex;
    flex-flow: row-reverse;
}

.wrapper_createnew {
    display: flex;
    align-items: center;
}

.wrapper-icon-create {
    position: relative;
    top: 26px;
    left: 30px;
}

// .ant-row {
//     font-size: 22px;

//     .ant-row .ant-form-item {
//         font-size: 22px;
//     }
// }
</style>
