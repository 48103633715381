<template>
    <div>
        <!-- formState.pay.slipimage : {{formState.pay.slipimage}} -->
        <a-form
            :model="formState"
            name="basic"
            autocomplete="off"
            @change="handleChange"
        >
            <!-- pay 1-->
            <!-- <pre>{{formState.pay}}</pre>  -->
            <!-- dynamic_payment :
            <pre>{{ dynamic_payment }}</pre> -->

            <!-- <pre>{{ dynamic_payment }}</pre> -->

            <!-- formState.pay.listpay : <pre>{{ formState.pay.listpay }}</pre> -->
            <!-- dynamic_payment.sights :
            -->

            <!-- <pre>{{ dynamic_payment }}</pre> -->
            <div v-for="(sight, index) in dynamic_payment.sights">
                <!-- <pre>{{ sight }}</pre> -->
                <!-- select {{ sight }} -->
                <!-- sight.slipimage : {{sight.slipimage}} -->
                <a-form-item
                    :name="['user', 'sellorderTransaction']"
                    label="ประเภท*"
                >
                    <a-row>
                        <a-col :span="18">
                            <a-radio-group
                                v-model:value="sight.paytype"
                                name="radioGroup"
                            >
                                <a-radio value="1">โอนเข้าบริษัท</a-radio>
                                <a-radio value="2">โอนเข้าบุคคลธรรมดา</a-radio>
                            </a-radio-group></a-col
                        >
                        <a-col :span="6" style="text-align: end;">
                            <a-popconfirm
                                title="ลบรายการ?"
                                @confirm="removeSight(sight)"
                            >
                                <a-button
                                    class="mt-3"
                                    type="danger"
                                    style="width: 70px;"
                                    :size="'small'"
                                    block
                                >
                                    <template #icon>
                                        <CloseCircleFilled />
                                    </template>
                                    ลบ
                                </a-button>
                            </a-popconfirm>
                        </a-col>
                    </a-row>
                    <a-row v-if="sight.paytype == '1'">
                        <a-radio-group
                            v-model:value="sight.iscalvat"
                            name="radioGroup"
                        >
                            <a-radio value="calvat">คิด VAT</a-radio>
                            <a-radio value="notcalvat">ไม่คิด VAT</a-radio>
                        </a-radio-group>
                    </a-row>
                </a-form-item>
                <!-- sight.slipimagearray:{{sight.slipimagearray}} -->
                <!-- formState.pay:{{formState.pay}} -->
                <a-form-item :name="['user', 'fileList']" label="อัพโหลดสลิป">
                    <div class="btn_slip">
                        <a-button
                            :disabled="!formState?.pay"
                            type="primary"
                            @click="showModaleasyslip"
                            >ประวัติตรวจสอบสลิป
                        </a-button>
                    </div>
                    <Uploadfile
                        :key="keyupdate"
                        :contactimageurl="sight.slipimage"
                        :sight="sight"
                        :sizeupimage="5"
                        @fileList="setupevent(sight, $event)"
                    >
                    </Uploadfile>
                    <!-- sight.slipimagearray: {{sight.slipimagearray}}
sight.numberref :{{sight.numberref}} -->
                    <a-modal
                        v-model:visible="visibleeasyslip"
                        title="ประวัติตรวจสอบสลิป"
                        @ok="handleOkeasyslip"
                    >
                        <div v-if="history_listeasy.length > 0" class="p-3">
                            <div
                                v-for="(Item, idx) of history_listeasy"
                                :key="`item-${idx}`"
                            >
                                <div v-if="Item?.ReacordEasySlipJSON">
                                    <a-typography-text
                                        type="success"
                                        class="pr-3"
                                        ><u
                                            >ตรวจสอบสำเร็จ ครั้งที่
                                            {{ idx + 1 }}</u
                                        ></a-typography-text
                                    >
                                    <a-progress
                                        v-if="
                                            JSON.parse(
                                                Item?.ReacordEasySlipJSON
                                            )?.url?.status
                                        "
                                        type="circle"
                                        :percent="100"
                                        :width="20"
                                    />
                                    <div>
                                        <a-typography-paragraph
                                            :copyable="{
                                                text: JSON.parse(
                                                    Item?.ReacordEasySlipJSON
                                                )?.url?.data?.transRef
                                            }"
                                        >
                                            เลขอ้างอิง :
                                            {{
                                                JSON.parse(
                                                    Item?.ReacordEasySlipJSON
                                                )?.url?.data?.transRef
                                            }}
                                        </a-typography-paragraph>
                                    </div>
                                    <div>
                                        โอนวันที่ :
                                        {{
                                            formatdate(
                                                JSON.parse(
                                                    Item?.ReacordEasySlipJSON
                                                )?.url?.data?.date
                                            )
                                        }}
                                    </div>
                                    <div>
                                        ธนาคารผู้โอน:
                                        {{
                                            JSON.parse(
                                                Item?.ReacordEasySlipJSON
                                            )?.url.data.sender.bank.name
                                        }}
                                    </div>

                                    <div>
                                        ชื่อผู้โอน:
                                        {{
                                            JSON.parse(
                                                Item?.ReacordEasySlipJSON
                                            )?.url.data.sender.account.name.th
                                        }}
                                    </div>
                                    <div>
                                        เลขบัญชีผู้โอน:
                                        {{
                                            JSON.parse(
                                                Item?.ReacordEasySlipJSON
                                            )?.url.data.sender.account.bank
                                                .account
                                        }}
                                    </div>
                                    <div>
                                        ธนาคารผู้รับ:
                                        {{
                                            JSON.parse(
                                                Item?.ReacordEasySlipJSON
                                            )?.url.data.receiver.bank.name
                                        }}
                                    </div>
                                    <div>
                                        ชื่อผู้รับ:
                                        {{
                                            JSON.parse(
                                                Item?.ReacordEasySlipJSON
                                            )?.url.data.receiver.account.name.th
                                        }}
                                    </div>
                                    <div>
                                        เลขบัญชีผู้รับ:
                                        {{
                                            JSON.parse(
                                                Item?.ReacordEasySlipJSON
                                            )?.url.data.receiver.account.bank
                                                .account
                                        }}
                                    </div>
                                    ยอดโอน :
                                    {{
                                        JSON.parse(
                                            Item?.ReacordEasySlipJSON
                                        )?.url?.data?.amount?.amount?.toLocaleString()
                                    }}
                                    บาท
                                </div>
                            </div>
                        </div>
                        <div v-else class="text-center">
                            ไม่พบข้อมูล
                        </div>
                    </a-modal>
                </a-form-item>
                <a-form-item
                    :name="['user', 'fileList']"
                    label="ช่องทางการชำระเงิน"
                >
                    <a-select
                        v-model:value="sight.paymentmethod"
                        show-search
                        placeholder="เลือกประเภท"
                        style="width: 200px"
                        :options="options"
                        :filter-option="filterOption"
                        @focus="handleFocus"
                        @blur="handleBlur"
                        @change="handleChange"
                    ></a-select>
                </a-form-item>

                <u>รายการที่ {{ index + 1 }}</u>
                <div v-if="sight.paymentmethod == 'โอนจากบัญชีไทย'">
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="โอนจากธนาคาร"
                                :name="['pay', 'frombank']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-select
                                    v-model:value="sight.frombank"
                                    :options="thaibank"
                                ></a-select>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="โอนเข้าธนาคาร"
                                :name="['pay', 'tobank']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-select
                                    v-model:value="sight.tobank"
                                    :options="thaibank"
                                    @change="handleChangeTobank(sight)"
                                ></a-select>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="2" :order="3">
                            <a-form-item
                                label="ชื่อบัญชี"
                                :name="['sights', index, 'price']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <!-- <a-select v-model:value="sight.bankname" show-search placeholder="ชื่อบัญชี"
                                    style="width: 300px" :options="accountname" :filter-option="filterOption"
                                    @focus="handleFocus" @blur="handleBlur" @change="handleChange"></a-select> -->

                                <!-- value:{{ value }} -->
                                <a-select
                                    ref="select"
                                    v-model:value="sight.bankname"
                                    :options="optionstest"
                                    :field-names="{
                                        label: 'name',
                                        value: 'id',
                                        options: 'children'
                                    }"
                                    @focus="handleChangeTobank(sight)"
                                    @change="handleChange"
                                ></a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ยอดเงิน"
                                :name="['pay', 'price']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input
                                    v-model:value="sight.price"
                                    @input="fomatNum()"
                                    type="number"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="วันที่"
                                :name="['pay', 'date']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-date-picker v-model:value="sight.date" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <!-- <pre>{{sight.time}}</pre> -->
                            <a-form-item
                                label="เวลา"
                                :name="['pay', 'time']"
                                :rules="{
                                    required: false,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <!-- <Time v-model:value="sight.time"></Time> -->
                                <a-input
                                    v-model:value="sight.timetext"
                                    @input="e => handleTimeInput(e, index)"
                                    placeholder="HH:mm"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1 1 300px" :order="3">
                            <a-form-item
                                label="หมายเลขอ้างอิง"
                                :name="['sights', index, 'price']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input
                                    v-model:value="sight.numberref"
                                    validate-status="warning"
                                    @input="handleKeyPressRef(sight.numberref)"
                                />
                            </a-form-item>
                            <!-- <NotiCheckRefNumber :numberref="sight.numberref"></NotiCheckRefNumber> -->
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-checkbox v-model:checked="sight.checked_pay_extra"
                            >เคสชำระเพิ่ม</a-checkbox
                        >
                    </a-row>
                    <a-row>
                        <a-col class="p-1" flex="1 1 300px" :order="4">
                            <a-form-item
                                label="หมายเหตุ"
                                :name="['pay', 'introduction']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.introduction"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </div>

                <div v-if="sight.paymentmethod == 'โอนจากบัญชีต่างประเทศ'">
                    <a-row type="flex">
                        <!-- <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="โอนจากธนาคาร"
                                :name="['pay', 'frombank']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-select
                                    v-model:value="sight.frombank"
                                    :options="thaibank"
                                ></a-select>
                            </a-form-item>
                        </a-col> -->
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="โอนเข้าธนาคาร"
                                :name="['pay', 'tobank']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-select
                                    v-model:value="sight.tobank"
                                    :options="thaibank"
                                    @change="handleChangeTobank(sight)"
                                ></a-select>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="2" :order="3">
                            <a-form-item
                                label="ชื่อบัญชี"
                                :name="['pay', 'accountname']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <!-- <a-select v-model:value="sight.accountname" show-search placeholder="ชื่อบัญชี"
                                    style="width: 300px" :options="accountname" :filter-option="filterOption"
                                    @focus="handleFocus" @blur="handleBlur" @change="handleChange"></a-select> -->

                                <a-select
                                    ref="select"
                                    v-model:value="sight.accountname"
                                    :options="optionstest"
                                    :field-names="{
                                        label: 'name',
                                        value: 'id',
                                        options: 'children'
                                    }"
                                    @focus="handleChangeTobank(sight)"
                                    @change="handleChange"
                                ></a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ชื่อผู้โอน"
                                :name="['pay', 'name']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.name" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2"> </a-col>
                        <a-col class="p-1" flex="1" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ยอดเงิน"
                                :name="['pay', 'price']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input
                                    v-model:value="sight.price"
                                    @input="fomatNum()"
                                    type="number"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="วันที่"
                                :name="['pay', 'date']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-date-picker
                                    :format="'DD/MM/YYYY'"
                                    v-model:value="sight.date"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <a-form-item
                                label="เวลา"
                                :name="['pay', 'time']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <Time v-model:value="sight.time"></Time>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <a-form-item
                                label="หมายเลขอ้างอิง"
                                :name="['pay', 'numberref']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.numberref" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col class="p-1" flex="1 1 300px" :order="4">
                            <a-form-item
                                label="หมายเหตุ"
                                :name="['pay', 'introduction']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.introduction"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="sight.paymentmethod == 'บัตรเครดิต'">
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ธนาคาร"
                                :name="['pay', 'bankname']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-select
                                    v-model:value="sight.bankname"
                                    :options="thaibank"
                                ></a-select>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2"> </a-col>
                        <a-col class="p-1" flex="1" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ยอดเงิน"
                                :name="['pay', 'price']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input
                                    v-model:value="sight.price"
                                    @input="fomatNum()"
                                    type="number"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="วันที่"
                                :name="['pay', 'date']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-date-picker
                                    :format="'DD/MM/YYYY'"
                                    v-model:value="sight.date"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <a-form-item
                                label="เวลา"
                                :name="['pay', 'time']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <Time v-model:value="sight.time"></Time>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <a-form-item
                                label="หมายเลขอ้างอิง"
                                :name="['pay', 'numberref']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.numberref" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col class="p-1" flex="1 1 300px" :order="4">
                            <a-form-item
                                label="หมายเหตุ"
                                :name="['pay', 'introduction']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.introduction"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="sight.paymentmethod == 'moneygram'">
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="หมายเลขรับเงิน"
                                :name="['pay', 'payoutnumber']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.payoutnumber" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1 1 300px" :order="2">
                            <a-form-item
                                label="ประเทศ"
                                :name="['pay', 'selectcountry']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-select
                                    show-search
                                    v-model:value="sight.selectcountry"
                                    placeholder="เลือกประเทศ"
                                    style="width: 200px"
                                    :options="options_country"
                                    :filter-option="filterOption"
                                    @focus="handleFocus"
                                    @blur="handleBlur"
                                    @change="handleChange"
                                ></a-select>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ชื่อผู้ส่ง"
                                :name="['pay', 'sendername']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.sendername" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="ชื่อผู้รับ"
                                :name="['pay', 'recipientname']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.recipientname" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ที่อยู่"
                                :name="['pay', 'address']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.address"
                                    placeholder="textarea with clear icon"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ยอดเงิน"
                                :name="['pay', 'price']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input
                                    v-model:value="sight.price"
                                    @input="fomatNum()"
                                    type="number"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="วันที่"
                                :name="['pay', 'date']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-date-picker
                                    :format="'DD/MM/YYYY'"
                                    v-model:value="sight.date"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <a-form-item
                                label="เวลา"
                                :name="['pay', 'time']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <Time v-model:value="sight.time"></Time>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <a-form-item
                                label="หมายเลขอ้างอิง"
                                :name="['pay', 'numberref']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.numberref" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col class="p-1" flex="1 1 300px" :order="4">
                            <a-form-item
                                label="หมายเหตุ"
                                :name="['pay', 'introduction']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.introduction"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="sight.paymentmethod == 'Ria'">
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="หมายเลขรับเงิน"
                                :name="['pay', 'payoutnumber']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input
                                    placeholder="8 หลัก"
                                    maxlength="8"
                                    v-model:value="sight.payoutnumber"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1 1 300px" :order="2">
                            <a-form-item
                                label="ประเทศ"
                                :name="['pay', 'selectcountry']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-select
                                    show-search
                                    v-model:value="sight.selectcountry"
                                    placeholder="เลือกประเทศ"
                                    style="width: 200px"
                                    :options="options_country"
                                    :filter-option="filterOption"
                                    @focus="handleFocus"
                                    @blur="handleBlur"
                                    @change="handleChange"
                                ></a-select>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ชื่อผู้ส่ง"
                                :name="['pay', 'sendername']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.sendername" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="ชื่อผู้รับ"
                                :name="['pay', 'recipientname']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.recipientname" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ที่อยู่"
                                :name="['pay', 'address']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.address"
                                    placeholder="textarea with clear icon"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ยอดเงิน"
                                :name="['pay', 'price']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input
                                    v-model:value="sight.price"
                                    @input="fomatNum()"
                                    type="number"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="วันที่"
                                :name="['pay', 'date']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-date-picker
                                    :format="'DD/MM/YYYY'"
                                    v-model:value="sight.date"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <a-form-item
                                label="เวลา"
                                :name="['pay', 'time']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <Time v-model:value="sight.time"></Time>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <a-form-item
                                label="หมายเลขอ้างอิง"
                                :name="['pay', 'numberref']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.numberref" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col class="p-1" flex="1 1 300px" :order="4">
                            <a-form-item
                                label="หมายเหตุ"
                                :name="['pay', 'introduction']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.introduction"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="sight.paymentmethod == 'western_union'">
                    <a-row type="flex">
                        <a-col class="p-1" flex="1 1 300px" :order="1">
                            <a-form-item
                                label="หมายเลขรับเงิน"
                                :name="['sights', index, 'price']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input
                                    placeholder="10 หลัก"
                                    maxlength="10"
                                    v-model:value="sight.payoutnumber"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1 1 300px" :order="2">
                            <a-form-item
                                label="ประเทศ"
                                :name="['pay', 'selectcountry']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-select
                                    show-search
                                    v-model:value="sight.selectcountry"
                                    placeholder="เลือกประเทศ"
                                    style="width: 200px"
                                    :options="options_country"
                                    :filter-option="filterOption"
                                    @focus="handleFocus"
                                    @blur="handleBlur"
                                    @change="handleChange"
                                ></a-select>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1 1 300px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ชื่อผู้ส่ง"
                                :name="['pay', 'sendername']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.sendername" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="ชื่อผู้รับ"
                                :name="['pay', 'recipientname']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.recipientname" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ที่อยู่"
                                :name="['pay', 'address']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.address"
                                    placeholder="textarea with clear icon"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ยอดเงิน"
                                :name="['pay', 'price']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input
                                    v-model:value="sight.price"
                                    @input="fomatNum()"
                                    type="number"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="วันที่"
                                :name="['pay', 'date']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-date-picker
                                    :format="'DD/MM/YYYY'"
                                    v-model:value="sight.date"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <a-form-item
                                label="เวลา"
                                :name="['pay', 'time']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <Time v-model:value="sight.time"></Time>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <a-form-item
                                label="หมายเลขอ้างอิง"
                                :name="['pay', 'numberref']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.numberref" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col class="p-1" flex="1 1 300px" :order="4">
                            <a-form-item
                                label="หมายเหตุ"
                                :name="['pay', 'introduction']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.introduction"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="sight.paymentmethod == 'Paypal'">
                    <a-row type="flex">
                        <a-col class="p-1" flex="1 1 300px" :order="1">
                            <a-form-item
                                label="ชื่อผู้ส่ง"
                                :name="['pay', 'sendername']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.sendername" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1 1 300px" :order="2"> </a-col>
                        <a-col class="p-1" flex="1 1 300px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1 1 300px" :order="1">
                            <a-form-item
                                label="ที่อยู่"
                                :name="['pay', 'sendername']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.address"
                                    placeholder="textarea with clear icon"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1 1 300px" :order="2"> </a-col>
                        <a-col class="p-1" flex="1 1 300px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ยอดเรียกเก็บเงิน"
                                :name="['pay', 'billingamount']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.billingamount" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="ยอดสุทธิ"
                                :name="['pay', 'netamount']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input v-model:value="sight.netamount" />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="วันที่"
                                :name="['pay', 'date']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-date-picker
                                    :format="'DD/MM/YYYY'"
                                    v-model:value="sight.date"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="เวลา"
                                :name="['pay', 'time']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <Time v-model:value="sight.time"></Time>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col class="p-1" flex="1 1 300px" :order="4">
                            <a-form-item
                                label="หมายเหตุ"
                                :name="['pay', 'introduction']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.introduction"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="sight.paymentmethod == 'อื่นๆ'">
                    <a-row type="flex">
                        <a-col class="p-1" flex="1 1 300px" :order="1">
                            <a-form-item
                                label="หมายเหตุ"
                                :name="['pay', 'introduction']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-textarea
                                    v-model:value="sight.introduction"
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="1">
                            <a-form-item
                                label="ยอดเงิน"
                                :name="['pay', 'price']"
                                :rules="{
                                    required: true,
                                    message: 'กรุณากรอก'
                                }"
                            >
                                <a-input
                                    v-model:value="sight.price"
                                    @input="fomatNum()"
                                    type="number"
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </div>
                <hr
                    v-show="index != dynamic_payment.sights.length - 1"
                    class="p-3"
                />
            </div>
            <div>
                <a-button
                    class="mt-3 m-auto"
                    type="dashed"
                    style="width:200px"
                    block
                    @click="addSight"
                >
                    <template #icon>
                        <PlusOutlined />
                    </template>
                    เพิ่มรายการ
                </a-button>
            </div>
        </a-form>
    </div>
</template>
<script>
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref, reactive, onMounted, watch } from "vue";
import Uploadfile from "./Uploadfile.vue";
import SelectOption from "../form/SelectOptionPay.vue";
import Time from "../form/Time.vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import _ from "lodash";
import dayjs from "dayjs";
import { formStateStore } from "../../../../../store/storebackoffice";
// import Util from "@/helper/Utility.js";
import { useRoute } from "vue-router";
import NotiCheckRefNumber from "@/module/CustomerOrder/SellCreate/component/NotiCheckRefNumber.vue";
import { notification } from "ant-design-vue";

import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
export default defineComponent({
    components: {
        Uploadfile,
        SelectOption,
        Time,
        CloseCircleFilled,
        PlusOutlined,
        NotiCheckRefNumber
    },
    props: {
        formStates: null
    },
    setup(props, { emit }) {
        const iscalvat = ref("calvat");
        const slipdetail = reactive([]);
        var data = [
            {
                ชื่อบัญชี: "บริษัท นะมะหายัน กรุ๊ป จำกัด",
                ธนาคาร: "(SCB)กระแสรายวัน",
                เลขบัญชี: "468-118-657-4",
                code: "SCB"
            },
            {
                ชื่อบัญชี: "บริษัท นะมะหายัน กรุ๊ป จำกัด",
                ธนาคาร: "(Kbank)ธนาคารกสิกรไทย",
                เลขบัญชี: "054-2-86856-2",
                code: "KBANK"
            },
            {
                ชื่อบัญชี: "บริษัท นะมะหายัน กรุ๊ป จำกัด",
                ธนาคาร: "(SCB)ธนาคารไทยพาณิชย์",
                เลขบัญชี: "171-427644-4",
                code: "SCB"
            },
            {
                ชื่อบัญชี: "น.ส.นภัสนันท์ พิชยะพัฒนกุล",
                ธนาคาร: "(BBL)ธนาคารกรุงเทพ",
                เลขบัญชี: "865-406-7878",
                code: "BBL"
            },
            {
                ชื่อบัญชี: "น.ส. พชรมน ธนภรณ์ทิชากร",
                ธนาคาร: "(BBL)ธนาคารกรุงเทพ",
                เลขบัญชี: "865-0-85231-5",
                code: "BBL"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(SCB)ธนาคารไทยพาณิชย์",
                เลขบัญชี: "171-419225-0",
                code: "SCB"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(Kbank)ธนาคารกสิกรไทย",
                เลขบัญชี: "103-1-34585-1",
                code: "KBANK"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(BBL)ธนาคารกรุงเทพ",
                เลขบัญชี: "093-7-31775-8",
                code: "BBL"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(KTB)ธนาคารกรุงไทย",
                เลขบัญชี: "488-3-01403-7",
                code: "KTB"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(Bay)ธนาคารกรุงศรีอยุธยา",
                เลขบัญชี: "467-1-87200-4",
                code: "BAY"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(GSB)ธนาคารออมสิน",
                เลขบัญชี: "020-3-6487926-0",
                code: "GSB"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(UOB)ธนาคารยูโอบี",
                เลขบัญชี: "800-184-871-2",
                code: "UOB"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(KKP)ธนาคารเกียรตินาคินภัทร",
                เลขบัญชี: "20-0304168-0",
                code: "KKP"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(CIMBT)ธนาคารซีไอเอ็มบีไทย",
                เลขบัญชี: "701-1-99798-8",
                code: "CIMBT"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(LH Bank)ธนาคารแลนด์ แอนด์ เฮ้าส์",
                เลขบัญชี: "890-2-18543-3",
                code: "LHBANK"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(BAAC)ธนาคาร ธกส",
                เลขบัญชี: "020-197-622-099",
                code: "BAAC"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "ธนาคารทีเอ็มบีธนชาต",
                เลขบัญชี: "230-7-08723-5",
                code: "TTB"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(SCB)ธนาคารไทยพาณิชย์",
                เลขบัญชี: "409-624877-5",
                code: "SCB"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(BBL)ธนาคารกรุงเทพ",
                เลขบัญชี: "865-4-02110-7",
                code: "BBL"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(KTB)ธนาคารกรุงไทย",
                เลขบัญชี: "488-3-18782-9",
                code: "KTB"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(Bay)ธนาคารกรุงศรีอยุธยา",
                เลขบัญชี: "788-1-14773-3",
                code: "BAY"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(GSB)ธนาคารออมสิน",
                เลขบัญชี: "204-0-927915-3",
                code: "GSB"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(UOB)ธนาคารยูโอบี",
                เลขบัญชี: "800-188-830-7",
                code: "UOB"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(KKP)ธนาคารเกียรตินาคินภัทร",
                เลขบัญชี: "20-1239698-8",
                code: "KKP"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(CIMBT)ธนาคารซีไอเอ็มบีไทย",
                เลขบัญชี: "70-1305407-7",
                code: "CIMBT"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "ธนาคารทีเอ็มบีธนชาต",
                เลขบัญชี: "230-7-20496-2",
                code: "TTB"
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(SCB)ธนาคารไทยพาณิชย์",
                เลขบัญชี: "171-4-00257-8",
                code: "SCB"
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(BBL)ธนาคารกรุงเทพ",
                เลขบัญชี: "865-0-82807-5",
                code: "BBL"
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(KTB)ธนาคารกรุงไทย",
                เลขบัญชี: "488-0-82789-4",
                code: "KTB"
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(Bay)ธนาคารกรุงศรีอยุธยา",
                เลขบัญชี: "467-1-77083-6",
                code: "BAY"
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(GSB)ธนาคารออมสิน",
                เลขบัญชี: "020-2-8283266-4",
                code: "GSB"
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(UOB)ธนาคารยูโอบี",
                เลขบัญชี: "230-2-73574-7",
                code: "UOB"
            },
            {
                ชื่อบัญชี: "BAOTHONG XAYYAVONG MS",
                ธนาคาร: "Bcel bank",
                เลขบัญชี: "010-12-00-00889322-001",
                code: "BCELBANK"
            }
        ];

        let convertedData = [];

        for (let entry of data) {
            let accountName = entry["ชื่อบัญชี"];
            let bankName = entry["ธนาคาร"];
            let accountNumber = entry["เลขบัญชี"];

            let accountExists = false;
            for (let item of convertedData) {
                if (item["id"] === accountName) {
                    item["children"].push({
                        id: `${accountName}|${bankName}|(${accountNumber})`,
                        name: `${bankName}  (${accountNumber})`
                    });
                    accountExists = true;
                    break;
                }
            }

            if (!accountExists) {
                convertedData.push({
                    id: accountName,
                    name: accountName,
                    children: [
                        {
                            id: `${accountName}|${bankName}|(${accountNumber})`,
                            name: `${bankName}  (${accountNumber})`
                        }
                    ]
                });
            }
        }

        const optionstest = ref(convertedData);

        const route = useRoute();
        const dateFormat = "YYYY-MM-DD";
        const dynamic_payment = reactive({
            sights: [],
            area: undefined
        });
        const accountname = ref([
            {
                value: "บริษัท นะมะหายัน กรุ๊ป จำกัด",
                label: "บริษัท นะมะหายัน กรุ๊ป จำกัด"
            },
            {
                value: "นางสาวนภัสนันท์ พิชยะพัฒนกุล",
                label: "นางสาวนภัสนันท์ พิชยะพัฒนกุล"
            },
            {
                value: "นายกัมปนาท มาลาขาว",
                label: "นายกัมปนาท มาลาขาว"
            },
            {
                value: "นางสาวเอื้อมพร คิดชอบ",
                label: "นางสาวเอื้อมพร คิดชอบ"
            },
            {
                value: "นางสาวทิพย์รัตน์ โชติพันธ",
                label: "นางสาวทิพย์รัตน์ โชติพันธ"
            }
        ]);
        const keyupdate = ref(0);
        const formState = reactive(formStateStore);
        setTimeout(() => {
            keyupdate.value++;
        }, 1000);
        const isinit = ref(false);
        console.log("props.formStates", props.formStates);

        const options = ref([
            {
                value: "โอนจากบัญชีไทย",
                label: "โอนจากบัญชีไทย"
            },
            {
                value: "โอนจากบัญชีต่างประเทศ",
                label: "โอนจากบัญชีต่างประเทศ"
            },
            {
                value: "บัตรเครดิต",
                label: "บัตรเครดิต"
            },
            {
                value: "moneygram",
                label: "moneygram"
            },
            {
                value: "Ria",
                label: "Ria"
            },
            {
                value: "western_union",
                label: "western union"
            },
            {
                value: "Paypal",
                label: "Paypal"
            },
            {
                value: "อื่นๆ",
                label: "อื่นๆ"
            }
        ]);
        const paytype = ref("1");
        const value = ref(undefined);
        const isinited = ref(false);
        const formRef = ref();
        const formItemContext = Form.useInjectFormItemContext();
        const handleChange = () => {
            formState.pay.paymentmethod = value.value;
            formState.pay.paytype = paytype.value;
            console.log("pay :", formState.pay);
            emit("update:value", formState.pay);
            formItemContext.onFieldChange();
        };
        onMounted(() => {
            setTimeout(() => {
                getpaymenthisory();
            }, 200);
        });

        const init_history_easyslip = () => {
            var sellordertransaction = route.query.so;
            var customercode = route.query.c;

            if (formState?.pay) {
                formState?.pay.forEach(property => {
                    if (property.numberref) {
                        var param = {
                            ProductSellOrder: sellordertransaction,
                            CustomerOrder: customercode,
                            TransRef: property.numberref
                        };
                        gethistoryeasyslip(param);
                    }
                });
            }
        };

        const history_listeasy = ref([]);
        const gethistoryeasyslip = async param => {
            await BzbsUser.apigethistoryeasyslip(param)
                .then(res => {
                    history_listeasy.value = res.data;
                    console.log(res.data);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const getpaymenthisory = async () => {
            console.log("2");

            //เคสเพิ่มขนาด จะใช้ payment ร่วมกัน
            var use_payment_of = "";
            if (route.query.ot) {
                await BzbsUser.apigetarraytran(route.query.ot).then(res => {
                    use_payment_of =
                        res.data.resultso[res.data.resultso.length - 1];
                    formState.productsellorder_for_main_payment = use_payment_of;
                });
                //ใช้ so payment ร่วมกัน เคสเพิ่มขนาด
                //เคสเพิ่มขนาด จะใช้ payment ร่วมกัน
            }
            await BzbsUser.apigetpaymentdetail(
                formState.user.customercode,
                "normal",
                use_payment_of || route.query.so
            )
                .then(res => {
                    formState.pay.listpay = [];
                    var result = [];
                    res.data.forEach(element => {
                        result.push(
                            _.transform(element, function(result, val, key) {
                                result[key.toLowerCase()] = val;
                            })
                        );
                    });

                    // value.value = res.data[0].paymentmethod;
                    // let resultobj = Object.assign(formState.pay, res.data[0]);
                    // if (res.data[0].date) {
                    //     resultobj.date = ref(dayjs(res.data[0].date, "YYYY-MM-DD")) || '';
                    // }
                    // if (res.data[0].time) {
                    //     resultobj.time = ref(dayjs(res.data[0].time, "HH:mm")) || '';
                    // }

                    // formState.pay = resultobj;

                    result.forEach(element => {
                        if (element.slipimage) {
                            element.slipimage = JSON.parse(element.slipimage);
                        }
                        if (element.selectcountry) {
                            element.selectcountry = JSON.parse(
                                element.selectcountry
                            );
                        }
                        if (element.date) {
                            element.date = ref(dayjs(element.date));
                        }
                        if (element.time) {
                            element.time = ref(dayjs(element.time));
                        }
                        if (element.timetext) {
                            element.timetext = element.timetext;
                        }

                        formState.pay.listpay.push(element);
                    });
                    // formState.pay.listpay = res.data;
                    keyupdate.value++;
                    dynamic_payment.sights = formState.pay.listpay;
                    init_dynamic_payment();
                    isinited.value = true;
                    return console.log(res.data[0]);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const thaibank = ref([
            { value: "(BBL)ธนาคารกรุงเทพ", label: "(BBL)ธนาคารกรุงเทพ" },
            { value: "(KTB)ธนาคารกรุงไทย", label: "(KTB)ธนาคารกรุงไทย" },
            {
                value: "(BAY)ธนาคารกรุงศรีอยุธยา",
                label: "(BAY)ธนาคารกรุงศรีอยุธยา"
            },
            { value: "(KBANK)ธนาคารกสิกรไทย", label: "(KBANK)ธนาคารกสิกรไทย" },
            { value: "(SCB)ธนาคารไทยพาณิชย์", label: "(SCB)ธนาคารไทยพาณิชย์" },
            { value: "(BAAC)ธนาคาร ธกส", label: "(BAAC)ธนาคาร ธกส" },
            { value: "(GHBANK)ธนาคาร ธอส", label: "(GHBANK)ธนาคาร ธอส" },
            {
                value: "(CIMBT)ธนาคารซีไอเอ็มบีไทย",
                label: "(CIMBT)ธนาคารซีไอเอ็มบีไทย"
            },
            {
                value: "(TTB)ธนาคารทหารไทยธนชาต",
                label: "(TTB)ธนาคารทหารไทยธนชาต"
            },
            {
                value: "(CITI)ธนาคารซิตี้แบงก์",
                label: "(CITI)ธนาคารซิตี้แบงก์"
            },
            { value: "(GSB)ธนาคารออมสิน", label: "(GSB)ธนาคารออมสิน" },
            { value: "(UOB)ธนาคารยูโอบี", label: "(UOB)ธนาคารยูโอบี" },
            {
                value: "(SC)ธนาคารสแตนดาร์ดชาร์เตอร์ด",
                label: "(SC)ธนาคารสแตนดาร์ดชาร์เตอร์ด"
            },
            { value: "(TISCO)ธนาคารทิสโก้", label: "(TISCO)ธนาคารทิสโก้" },
            { value: "(TCB)ธนาคารไทยเครดิต", label: "(TCB)ธนาคารไทยเครดิต" },
            { value: "(TTB)ธนาคารทหารไทย", label: "(TTB)ธนาคารทหารไทย" },
            {
                value: "(KKP)ธนาคารเกียรตินาคินภัทร",
                label: "(KKP)ธนาคารเกียรตินาคินภัทร"
            },
            {
                value: "(LHBANK)ธนาคารแลนด์แอนด์เฮ้าส์",
                label: "(LHBANK)ธนาคารแลนด์แอนด์เฮ้าส์"
            },
            { value: "(BCELBANK)BCELBANK", label: "(BCELBANK)BCELBANK" }
        ]);
        const fomatNum = val => {
            //formState.pay.price = Util.formatNum(val, 0);
        };

        const handleChangeTobank = value => {
            var paytype = value.paytype;
            var tobank = value.tobank;
            if (tobank == undefined) {
                optionstest.value = convertedData;
                return;
            }
            var match = tobank?.match(/\((.*?)\)/);
            var code_bank_selected = match[1];
            if (code_bank_selected) {
                let convertedData = [];

                for (let entry of data) {
                    let accountName = entry["ชื่อบัญชี"];
                    let bankName = entry["ธนาคาร"];
                    let accountNumber = entry["เลขบัญชี"];
                    let code = entry["code"];

                    if (code == code_bank_selected) var accountExists = false;
                    for (let item of convertedData) {
                        console.log("item :", item);
                        if (item["id"] === accountName) {
                            if (code == code_bank_selected) {
                                item["children"].push({
                                    id: `${accountName}|${bankName}|(${accountNumber})`,
                                    name: `${bankName}  (${accountNumber})`
                                });
                            }
                            accountExists = true;
                            break;
                        }
                    }

                    if (!accountExists) {
                        if (code == code_bank_selected) {
                            convertedData.push({
                                id: accountName,
                                name: accountName,
                                children: [
                                    {
                                        id: `${accountName}|${bankName}|(${accountNumber})`,
                                        name: `${bankName}  (${accountNumber})`
                                    }
                                ]
                            });
                        }
                        console.log("convertedData :", convertedData);
                    }
                }

                if (paytype == "1") {
                    convertedData = convertedData.filter(function(obj) {
                        return obj.id == "บริษัท นะมะหายัน กรุ๊ป จำกัด";
                    });
                }
                optionstest.value = convertedData;
            }
        };
        const removeSight = item => {
            let index = dynamic_payment.sights.indexOf(item);

            if (index !== -1) {
                dynamic_payment.sights.splice(index, 1);
            }

            // formState.user.customerorder

            var param = {
                ProductSellOrder: formState.user.sellorderTransaction,
                CustomerOrder: formState.user.customercode,
                ProductKey: item.productkey
            };
            apideleteupdatepayment(param);
        };

        const apideleteupdatepayment = param => {
            BzbsUser.apideleteupdatepayment(param)
                .then(res => {})
                .catch(error => {
                    console.log(error);
                });
        };
        const addSight = () => {
            dynamic_payment.sights.push({
                value: undefined,
                price: undefined,
                id: Date.now()
            });
        };

        const init_dynamic_payment = () => {
            if (dynamic_payment.sights.length == 0) {
                setTimeout(() => {
                    addSight();
                }, 1000);
            }
        };

        const options_country = ref([]);
        BzbsUser.apicountries()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    options_country.value.push({
                        value: [
                            res.data[element].CountryNameTh,
                            res.data[element].CountryCode
                        ],
                        label: res.data[element].CountryNameTh
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const handleBlur = () => {
            console.log("blur");
        };

        const handleFocus = () => {
            console.log("focus");
        };

        const filterOption = (input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        watch(
            () => dynamic_payment.sights,
            () => {
                formState.pay = dynamic_payment.sights;
            }
        );

        watch(dynamic_payment, async (newQuestion, oldQuestion) => {
            formState.pay.listpay = dynamic_payment.sights;
        });
        const timer = ref(null);
        const messageorder = ref("");
        const handleKeyPressRef = numberref => {
            if (numberref?.trim()?.length == 0) {
                messageorder.value = "";
                return;
            }
            clearTimeout(timer.value); // Clear any existing timer

            timer.value = setTimeout(async () => {
                // Call your API here

                await BzbsUser.getcheckrefnumber(numberref)
                    .then(res => {
                        if (res.data.length >= 1) {
                            warningnumberref(res.data[0]);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }, 1000); // Delay the API call for 2 seconds (adjust as needed)
        };
        const warning = message => {
            notification["warning"]({
                message: message
            });
        };
        const warningnumberref = ref => {
            var placement = "bottomRight";
            notification.open({
                message: "หมายเลขอ้างอิงซ้ำในระบบ",
                description:
                    ref.NumberRef +
                    `\n หมายเลขอ้างอิงซ้ำกับรายการ ${ref.ProductSellOrder}`,
                placement,
                style: {
                    width: "600px",
                    marginLeft: `${335 - 600}px`
                },
                class: "notification-custom-class"
            });
        };

        const handleFileList = $event => {
            // Assuming $event is an array you want to assign to both properties
            this.sight.slipimagearray = $event;
            this.sight.easyslip = $event;
        };
        const formatdate = date => {
            if (moment(date).isValid()) {
                return moment(date)
                    .add(543, "year")
                    .format("L");
            } else {
                return "-";
            }
        };

        const visibleeasyslip = ref(false);
        const showModaleasyslip = () => {
            visibleeasyslip.value = true;
            setTimeout(() => {
                init_history_easyslip();
            }, 1000);
        };
        const handleOkeasyslip = e => {
            console.log(e);
            visibleeasyslip.value = false;
        };

        // Define the handling logic
        const handleTimeInput = (e, index) => {
            const input = e.target.value;
            const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;

            // Remove non-numeric and non-colon characters
            const cleanedInput = input.replace(/[^\d:]/g, "");

            // Format as HH:mm
            const formattedInput = cleanedInput.replace(
                /^(\d{1,2})?:?(\d{0,2})?$/,
                (match, p1, p2) => {
                    return p1 ? (p2 ? `${p1}:${p2}` : `${p1}`) : "";
                }
            );

            // Update the sight's timetext property
            dynamic_payment.sights[index].timetext = formattedInput;
        };

        const setupevent = (sight, $event) => {
            try {
                debugger
                sight.slipimagearray = $event;
                if (
                    sight.slipimagearray?.[0].slipdetail?.[0]?.Status == "200"
                ) {
                    var detail =
                        sight.slipimagearray?.[0].slipdetail?.[0]
                            ?.ReacordEasySlipJSON;
                    var sub_detail = null;
                    try {
                        sub_detail = JSON.parse(detail);
                    } catch (error) {}

                    sight.paytype = sub_detail?.url?.data?.receiver?.account?.name?.th?.includes(
                        "บริษัท"
                    )
                        ? "1"
                        : "2";
                    sight.iscalvat = "calvat";
                    sight.paymentmethod = "โอนจากบัญชีไทย";
                    sight.numberref =
                        sight.slipimagearray?.[0].slipdetail?.[0]?.TransRef;

                    var text_frombank = thaibank.value.filter(bank =>
                        bank.value.includes(
                            sub_detail.url.data.sender.bank.short
                        )
                    );
                    var text_tobank = thaibank.value.filter(bank =>
                        bank.value.includes(
                            sub_detail.url.data.receiver.bank.short
                        )
                    );
                    sight.frombank = text_frombank[0].value;
                    sight.tobank = text_tobank[0].value;
                    sight.price = sub_detail.url.data.amount.amount;

                    const dateTimeString = sub_detail.url.data.date;
                    const date = new Date(dateTimeString);
                    const hours = String(date.getHours()).padStart(2, "0");
                    const minutes = String(date.getMinutes()).padStart(2, "0");
                    const timeString = hours + ":" + minutes;
                    sight.timetext = timeString;
                    sight.date = ref(
                        dayjs(convertDateFormat(sub_detail.url.data.date))
                    );

                    var account_to =
                        sub_detail.url.data.receiver.account.bank.account;
                    var short_bank =
                        sub_detail.url.data.receiver.bank.short;
                    optionstest.value = convertedData
                    const result = findTextInChildren(
                        optionstest.value,
                        account_to?.replace(/X|x|-/g, ""),
                        short_bank
                    );

                    // setTimeout(() => { 
                        debugger
                        optionstest.value = result;
                        if (result.length > 0) {
                        sight.bankname = result[0]?.id;
                    } 
 
                    // }, 500); 
                    // ตั้งค่า sight.bankname หลังจาก findTextInChildren ทำงานเสร็จ
                   
                    // else {
                    //     sight.bankname = null; // หรือค่าเริ่มต้นที่เหมาะสมในกรณีที่ไม่พบผลลัพธ์
                    // }
                }
            } catch (error) {
                console.log(error);
            }
        };

        const findTextInChildren = (array, searchText,short_bank) => { 
            try {
                short_bank = short_bank.toUpperCase()
                var last_num = ''
                if (searchText.length > 3){ 
                    last_num = searchText.slice(-3);
                }

                const foundItems = [];
                array.forEach(item => { 
                    if (item.children) {
                        item.children.forEach(child => {
                            if (
                                child.name
                                    ?.replace(/[^0-9]/g,"")
                                    .includes(last_num) && 
                                    child.name?.replace(/[^a-zA-Z.\s]/g,"")?.toUpperCase()?.trim()?.includes(short_bank)
                            ) {
                                foundItems.push(child);
                            }
                        });
                    }
                });
                return foundItems;
            } catch (error) {
                console.log(error);
                return [];
            }
        };

        const convertDateFormat = isoDate => {
            try {
                // แปลง ISO 8601 เป็นวัตถุ Date
                const date = new Date(isoDate);

                // สร้างรูปแบบวันที่ตามที่ต้องการ
                const options = {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    timeZone: "GMT",
                    timeZoneName: "short"
                };

                // แปลงวันที่เป็นรูปแบบที่ต้องการ
                const formattedDate = date.toLocaleString("en-GB", options);
                console.log("formattedDate ", formattedDate);
                return formattedDate;
            } catch (error) {
                console.log(error);
            }
        };

        return {
            handleTimeInput,
            visibleeasyslip,
            showModaleasyslip,
            handleOkeasyslip,
            handleFileList,
            options_country,
            handleBlur,
            handleFocus,
            filterOption,
            removeSight,
            addSight,
            fomatNum,
            keyupdate,
            isinited,
            value,
            formRef,
            paytype,
            options,
            isinit,
            formState,
            thaibank,
            formStateStore,
            accountname,
            handleChange,
            dynamic_payment,
            optionstest,
            handleKeyPressRef,
            slipdetail,
            history_listeasy,
            formatdate,
            iscalvat,
            handleChangeTobank,
            setupevent
        };
    }
});
</script>
<style lang="scss">
.notification-custom-class {
    color: red !important;
}

.btn_slip {
    float: right;
    padding: 0px 0px 10px 0px;
}
</style>
